import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';

@Injectable()
export class DebugGuard implements CanActivate, CanLoad {
  constructor() {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return localStorage.getItem('modeDebug') === 'true';
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return localStorage.getItem('modeDebug') === 'true';
  }
}
