import { Inject, Injectable } from '@angular/core';

export declare type ConfigOptionsType =
  | 'deployOptions'
  | 'globalOptions'
  | 'headerOptions'
  | 'loginOptions'
  | 'punchOptions'
  | 'versionOptions';

export interface IConfig {
  deployOptions?: IConfigDeployOptions;
  globalOptions?: IConfigGlobalOptions;
  headerOptions?: IConfigHeaderOptions;
  loginOptions?: IConfigLoginOptions;
  punchOptions?: IConfigPunchOptions;
  versionOptions?: IConfigVersionOptions;
}

interface IConfigDeployOptions {
  appId?: string;
  channel?: string;
  graceTimeApplyUpdate?: number;
  graceTimeCheckUpdate?: number;
  host?: string;
  updateBackground?: boolean;
}

export interface IConfigLoginOptions {
  delayGuard?: number;
  params?: any;
  url?: string;
}

export interface IConfigGlobalOptions {
  appId?: {
    android: string;
    ios: string;
  };
  deviceUUID?: string;
  host?: string;
  apiUrl?: string;
  enableCache?: boolean;
  enablePushNotification?: boolean;
  isProd?: boolean;
}

export interface IConfigPunchOptions {
  /** Active Service Snapshot , default true */
  enableTakeSnapshot?: boolean;
  /** Active snapshot on login (Does not work if enableTakeSnapshot is set to false), default true */
  enableTakeSnapshotOnLogin?: boolean;
  /** Active snapshot on login (Does not work if enableTakeSnapshot is set to false), default true */
  enableTakeSnapshotOnPunch?: boolean;
}

export interface IConfigHeaderOptions {
  menu?: {
    enableProfile: boolean;
    enableLogout: boolean;
  };
}

export interface IConfigVersionOptions {
  name?: string;
  version?: string;
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
  deployOptions: IConfigDeployOptions = {};
  globalOptions: IConfigGlobalOptions = {};
  headerOptions: IConfigHeaderOptions = {};
  loginOptions: IConfigLoginOptions = {};
  punchOptions: IConfigPunchOptions = {};
  versionOptions: IConfigVersionOptions = {};
  private readonly keyStore = 'ConfigService';

  constructor(@Inject('config') private config: IConfig) {
    this.deployOptions = this.getConfigDeployOptions(config);
    this.globalOptions = this.getConfigGlobalOptions(config);
    this.headerOptions = this.getConfigHeaderOptions(config);
    this.loginOptions = this.getConfigLoginOptions(config);
    this.punchOptions = this.getConfigPunchOptions(config);
    this.versionOptions = this.getConfigVersionOptions(config);
  }

  setLocalStorage(data, categoryKey: string, propKey: string) {
    const config = this.getLocalStorageConfig();
    const categoryValue = { ...this[categoryKey], ...config[categoryKey] };
    const newDataCategory = { ...categoryValue, ...{ [propKey]: data } };
    this[categoryKey] = newDataCategory;
    localStorage.setItem(this.keyStore, JSON.stringify({ ...config, [categoryKey]: newDataCategory }));
  }

  getLocalStorageConfig(key?: string) {
    const data = JSON.parse(localStorage.getItem(this.keyStore)) || {};
    return key ? data[key] || {} : data;
  }

  private getConfigGlobalOptions({ globalOptions }: IConfig): IConfigGlobalOptions {
    const { appId, host, apiUrl, isProd, enableCache, enablePushNotification, deviceUUID } = globalOptions || {};
    return this.mergeData('globalOptions', {
      appId,
      host,
      apiUrl,
      isProd,
      enableCache,
      enablePushNotification,
      deviceUUID,
    });
  }

  private getConfigDeployOptions({ deployOptions }: IConfig) {
    const {
      appId,
      channel,
      updateBackground = false,
      graceTimeApplyUpdate = 1,
      graceTimeCheckUpdate = 0,
      host,
    } = deployOptions || {};
    return this.mergeData('deployOptions', {
      appId,
      channel,
      graceTimeApplyUpdate,
      graceTimeCheckUpdate,
      host,
      updateBackground,
    });
  }

  private getConfigLoginOptions({ loginOptions }: IConfig) {
    const { url, params = {}, delayGuard = 300 } = loginOptions || {};
    return this.mergeData('loginOptions', { url, params, delayGuard });
  }

  private getConfigVersionOptions({ versionOptions }: IConfig) {
    const { name, version } = versionOptions || {};
    return this.mergeData('versionOptions', { name, version });
  }

  private getConfigPunchOptions({ punchOptions }: IConfig) {
    const {
      enableTakeSnapshot = true,
      enableTakeSnapshotOnLogin = true,
      enableTakeSnapshotOnPunch = true,
    } = punchOptions || {};
    return this.mergeData('punchOptions', { enableTakeSnapshot, enableTakeSnapshotOnLogin, enableTakeSnapshotOnPunch });
  }

  private getConfigHeaderOptions({ headerOptions }: IConfig) {
    const { menu } = headerOptions || {};
    return this.mergeData('headerOptions', { menu: { enableLogout: true, enableProfile: true, ...menu } });
  }

  private mergeData(key: string, data) {
    const storage = this.getLocalStorageConfig(key);
    return Object.entries({ ...data, ...storage }).reduce(this.reformatValidKeys, {});
  }

  private reformatValidKeys(acc, [key, value]) {
    return typeof value === 'undefined' ? acc : { ...acc, [key]: value };
  }
}
