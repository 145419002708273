import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { IAgendaComment } from '@app/core/interfaces/agenda-event-comment.interface';
import { FormatFullNamePipe } from '@app/core/pipes/format-full-name/format-full-name.pipe';
import { ConfigService } from '@app/core/services/config/config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AgendaEventCommentsResolve implements Resolve<IAgendaComment[]> {
  constructor(
    private http: HttpClient,
    private formatFullNamePipe: FormatFullNamePipe,
    private configService: ConfigService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IAgendaComment[]> {
    const { eventId } = route.params;
    const { host, apiUrl } = this.configService.globalOptions;

    return this.http.get<IAgendaComment[]>(`${host}${apiUrl}/mobile/agenda/comments/${eventId}`).pipe(
      map((agendaComments) => {
        return agendaComments.map(({ firstname, lastname, ...comment }) => ({
          ...comment,
          employeeFullName: this.formatFullNamePipe.transform({ lastname, firstname }),
        }));
      }),
    );
  }
}
