export const locale = {
  lang: 'en',
  data: {
    SERVICE: {
      ITINERARY: {
        NAVIGATOR_CHOICE_TEXT: 'Choose navigation app',
        NAVIGATOR_CHOICE_CANCEL: 'Cancel',
        NAVIGATOR_REMEMBER_CHOICE_HEADER: 'Remember my choice',
        NAVIGATOR_CHOICE_REMEMBER_TEXT: 'Do you want to always use this app in the future ?',
        NAVIGATOR_REMEMBER_CHOICE_YES: 'Yes',
        NAVIGATOR_REMEMBER_CHOICE_NO: 'No',
      },
    },
  },
};
