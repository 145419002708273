import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SwipeCardDialogComponent } from './swipe-card-dialog.component';
import { SwipeModule } from '../../behaviors/swipe/swipe.module';
import { ColorModule } from '../../directives/colors/color.module';
import { NoDataModule } from '../../components/no-data/no-data.module';
import { PipesModule } from '../../pipes/pipes.module';
import { IosModule } from '../../directives/ios/ios.module';
import { MaterialDesignModule } from '../../3p/material-design.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    MaterialDesignModule,
    ColorModule,
    NoDataModule,
    SwipeModule,
    PipesModule,
    IosModule,
  ],
  exports: [SwipeCardDialogComponent],
  declarations: [SwipeCardDialogComponent],
})
export class SwipeCardDialogModule {}
