import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConfigService } from '../config/config.service';

@Injectable()
export class UserDataSynchronizerService {
  private prefetchUrls: string[];

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private authenticationService: AuthenticationService,
  ) {
    const { host, apiUrl } = this.configService.globalOptions;
    this.prefetchUrls = [`${host}${apiUrl}/mobile/punch`];
  }

  preloadUserData() {
    if (this.authenticationService.currentUserValue) {
      this.prefetchUrls.map((url) => {
        this.httpClient.get(url).subscribe();
      });
    }
  }
}
