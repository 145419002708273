import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { format } from 'date-fns';
import { HttpClient } from '@angular/common/http';
import { ITimeclockViewModel } from '@app/core/interfaces/timeclock.interface';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class TimeclocksResolve implements Resolve<ITimeclockViewModel[]> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<ITimeclockViewModel[]> | Promise<ITimeclockViewModel[]> | ITimeclockViewModel[] {
    const { date = format(new Date(), 'yyyy-MM-dd') } = route.queryParams;
    return this.getTimeclocks(date);
  }

  getTimeclocks(date) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<ITimeclockViewModel[]>(`${host}${apiUrl}/mobile/timeclocks/${date}`);
  }
}
