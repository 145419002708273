import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ColorModule } from '../../directives/colors/color.module';
import { SnackBarTemplateComponent } from './snack-bar-template.component';
import { MaterialDesignModule } from '../../3p/material-design.module';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, MaterialDesignModule, TranslateModule, ColorModule, RouterModule],
  exports: [SnackBarTemplateComponent],
  declarations: [SnackBarTemplateComponent],
})
export class SnackBarTemplateModule {}
