<div
  [class]="contentCss || 'p-h-16 p-t-16 p-b-72'"
  appColor="tertiary"
  class="mat-elevation-z4"
  fxLayout="column"
  fxLayoutAlign="space-around center">
  <ng-content select="[slot=header]"></ng-content>
</div>
<app-sticky-element>
  <div class="header-md mat-elevation-z6"></div>
</app-sticky-element>

<div [class]="'m-t--72'" fxLayout="row" fxLayoutAlign="end center">
  <ng-content select="[slot=actions]"></ng-content>
</div>

<div [class]="overlapCss" class="m-b-4">
  <ng-content select="[slot=content]"></ng-content>
</div>
