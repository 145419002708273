import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TemplateDialogComponent } from './template-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialDesignModule } from '../../3p/material-design.module';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, MaterialDesignModule, TranslateModule],
  exports: [TemplateDialogComponent],
  declarations: [TemplateDialogComponent],
})
export class TemplateDialogModule {}
