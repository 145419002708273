import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { noop } from 'rxjs';

@Injectable()
export class BackButtonNativeService {
  constructor(private platform: Platform) {}

  onBackButtonNative(options?: { priority: number; callback: () => void }) {
    const { priority = 9999, callback = noop } = options || {};
    return this.platform.backButton.subscribeWithPriority(priority, callback);
  }
}
