/* eslint-disable complexity */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { ErrorService } from '../services/error/error.service';
import { IFirebaseCrashlyticsMessageOptions } from '../services/firebase-crashlytics/firebase-crashlytics.service';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private errorService: ErrorService,
    private navCtrl: NavController,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(({ status, statusText, error }) => {
        if (!status) {
          error = 'Connection error: server.connection.error.001';
        }

        return (
          this.checkAuth(request, status) ||
          this.checkVersionUpdate(status) ||
          this.errorService.checkErrorString(error) ||
          this.throwError(error.message || statusText, { code: status })
        );
      }),
    );
  }

  private async throwError(message: string, options: IFirebaseCrashlyticsMessageOptions) {
    await this.authenticationService.firebaseCrashlyticsService.recordException(message, options);
    return from(throwError(message)) as any;
  }

  private checkAuth(request, status) {
    if (status !== 401) {
      return false;
    }

    if (request.headers.get('grantsRetryRequest')) {
      this.authenticationService.logout();
      location.reload();
      return throwError('');
    }
  }

  private checkVersionUpdate(status) {
    if (status !== 412) {
      return false;
    }
    return this.navCtrl.navigateRoot('/update');
  }
}
