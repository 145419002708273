import { BehaviorSubject, Observable } from 'rxjs';
import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { IRequestCacheItem } from '../services/requests-caching/request-cached-item.interface';
import { IUserInformation } from '../interfaces/user-information.interface';
import { SqliteService } from '../services/sqlite/sqlite.service';
import { IRequestCachedItem } from '../services/requests-caching/request-cache-item.interface';
import { Capacitor } from '@capacitor/core';
import { ConfigService } from '../services/config/config.service';

export abstract class CachingModel {
  isReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected sqliteService: SqliteService;
  protected database: SQLiteObject;

  protected constructor(sqliteService: SqliteService, name: string, configService: ConfigService) {
    if (Capacitor.isNativePlatform() && configService.globalOptions?.enableCache) {
      this.sqliteService = sqliteService;
      sqliteService.initDatabase(name).then((database) => {
        this.database = database;
        this.isReady.next(true);
      });
    } else {
      this.isReady.next(true);
    }
  }

  async addItem(key: string, content: unknown, tableName: string, userInformation?: IUserInformation): Promise<void> {
    await this.createTable(tableName);

    const cacheItem = this.mapCacheItem(key, content, userInformation);
    await this.expiredCache(key, tableName, userInformation);

    return this.sqliteService.insert(this.database, tableName, cacheItem);
  }

  getDatabaseState(): Observable<boolean> {
    return this.isReady.asObservable();
  }

  abstract getItem(key: string, tableName: string, userInformation?: IUserInformation): Promise<IRequestCacheItem>;

  abstract mapCacheItem(key: string, content: unknown, userInformation?: IUserInformation): IRequestCachedItem;

  abstract expiredCache(key: string, tableName: string, userInformation?: IUserInformation);

  abstract createTable(tableName: string): Promise<void>;
}
