import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {  Router } from '@angular/router';

import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { Capacitor } from '@capacitor/core';
import CryptoJS from 'crypto-js';
import { TranslationLoaderBehaviors } from '../../behaviors/translate/translation-loader.behaviors';
import { AuthenticationCachingService } from '../../services/authentication-caching/authentication-caching.service';
import { NetworksService } from '../../services/networks/networks.service';
import { PushNotificationsBehaviors } from '../../behaviors/push-notifications/push-notifications.behaviors';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { PermissionService } from '../../services/permission/permission.service';
import { UserDataSynchronizerService } from '../../services/user-data-synchronizer/user-data-synchronizer.service';
import { ConfigService } from '../../services/config/config.service';
import { ILoginOptions } from '../../interfaces/login-options.interface';
import { RouterService } from '../../services/router/router.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @Input() options: ILoginOptions;
  @Input() onBack: () => void;
  @Input() isBusy = false;
  private unsubscribeAll: Subject<string>;
  private readonly snapshotUrl: string;
  @Output() login: EventEmitter<boolean> = new EventEmitter<boolean>();
  user: { username: string; password: string };
  loginForm: FormGroup;
  loading = false;
  showPassword = false;
  submitted = false;
  error = '';
  lastPage: string;
  displayNotification = false;
  highUnreadMsgCount = 0;

  constructor(
    private translationLoaderBehaviors: TranslationLoaderBehaviors,
    private configService: ConfigService,
    private authenticationCachingService: AuthenticationCachingService,
    private networksService: NetworksService,
    private pushNotificationsBehaviors: PushNotificationsBehaviors,
    private authenticationService: AuthenticationService,
    private userDataSynchronizerService: UserDataSynchronizerService,
    private permissionService: PermissionService,
    private navCtrl: NavController,
    private routerService: RouterService,
    private router: Router,
  ) {
    this.translationLoaderBehaviors.loadTranslations(english, french);
    this.unsubscribeAll = new Subject();
    this.snapshotUrl = this.router.routerState.snapshot.url;
    this.routerService.onPageChanged.pipe(takeUntil(this.unsubscribeAll)).subscribe(({ isOnPageChanged, lastPage }) => {
      if (this.snapshotUrl?.includes(lastPage) && lastPage !== this.snapshotUrl) {
        this.lastPage = `${lastPage}`;
      }
      this.isBusy = isOnPageChanged;
      this.displayNotification =
        this.authenticationService.isAuthenticated() &&
        this.highUnreadMsgCount &&
        !this.router.url.includes('/tabs/messages');
    });
  }

  ngOnInit() {
    this.options = { showLogo: true, visibleUsername: true, ...(this.options || {}) };
    this.resetForm();
    this.loginForm = this.createUserForm();
  }
  goBack() {
    if (this.onBack) {
      return this.onBack();
    }
    return this.navCtrl.navigateBack(this.lastPage ? this.lastPage : '');
  }

  createUserForm(): FormGroup {
    const { username = '' } = this.options.additionalDataBody || {};
    this.user = {
      username,
      password: '',
    };

    if (localStorage.getItem('rememberMe')) {
      this.user.username = localStorage.getItem('rememberMe-username');
    }

    return new FormGroup({
      username: new FormControl(this.user.username, [Validators.required]),
      password: new FormControl(this.user.password, [Validators.required]),
      rememberMe: new FormControl(!!localStorage.getItem('rememberMe')),
    });
  }

  async onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    const {
      username: { value: username },
      password: { value: password },
      rememberMe: { value: rememberMe },
    } = this.loginForm.controls;

    if (this.options.callback) {
      await this.onCallback({ username, password, ...this.options.additionalDataBody });
    } else {
      if (!this.networksService.isOnline) {
        await this.onSubmitOffline({ username, password });
      } else {
        await this.onSubmitOnline({ username, password, rememberMe, ...this.options.additionalDataBody });
      }
    }
  }

  async redirect(page) {
    await this.options.router.navigate(page);
  }

  private async onCallback(data) {
    this.authenticationService
      .login(data, { ...this.options })
      .then(() => {
        this.options.callback(true);
        this.login.emit(true);
      })
      .catch(() => {
        this.options.callback(false);
        this.login.emit(false);
      })
      .finally(() => {
        this.loading = false;
        this.resetForm();
      });
  }

  private async onSubmitOnline(data) {
    const { rememberMe, ...dataBody } = data;
    const { username, password } = dataBody;
    this.authenticationService
      .login(dataBody)
      .then(() => this.permissionService.fetchGrants())
      .then(async () => {
        this.initRememberMe(rememberMe, username);

        this.loading = false;
        const { enableCache } = this.configService.globalOptions;
        if (enableCache) {
          await this.initCacheLogin({ username, password });
          this.userDataSynchronizerService.preloadUserData();
        }

        this.login.emit(true);
        await this.options.router.navigate([this.options.returnUrl]).finally(() => this.resetForm());
      })
      .catch((error) => {
        this.error = error;
        this.loading = false;
        this.login.emit(false);
      });
  }

  // eslint-disable-next-line complexity
  private async onSubmitOffline({ username, password }) {
    const { enableCache } = this.configService.globalOptions;
    if (Capacitor.isNativePlatform() && enableCache) {
      const { value, isExpired } = (await this.authenticationCachingService.getItem(username, password)) || {};
      if (value && !isExpired) {
        this.authenticationService.initSession(value);
        this.login.emit(true);
        await this.options.router.navigate(this.options.navigateOffline).finally(() => this.resetForm());
      } else {
        this.error = 'SERVER.AUTH.WARNING.002';
        this.loading = false;
      }
    }
  }

  private async initCacheLogin({ username, password }) {
    const { enableCache } = this.configService.globalOptions;
    if (Capacitor.isNativePlatform() && enableCache) {
      if (this.networksService.isOnline) {
        const { token } = JSON.parse(localStorage.getItem('currentUser'));
        const data = {
          password: CryptoJS.SHA256(password).toString(),
          token: CryptoJS.AES.encrypt(token, password).toString(),
        };
        await this.authenticationCachingService.addItem(username, data);
      }
    }
  }

  private resetForm() {
    if (this.formGroupDirective) {
      this.formGroupDirective.resetForm();
    }
    if (this.loginForm) {
      this.loginForm.reset();
    }
    this.error = '';
  }

  private initRememberMe(rememberMe, username) {
    if (rememberMe) {
      localStorage.setItem('rememberMe', `${true}`);
      localStorage.setItem('rememberMe-username', username);
    } else {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('rememberMe-username');
    }
  }
}
