import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface INavigator {
  languages?: string;
  language?: string;
  browserLanguage?: string;
  userLanguage?: string;
}

interface IWindow {
  navigator?: INavigator;
}

export interface ITranslateLanguage {
  id: string;
  title: string;
  flag: string;
}

@Injectable()
export class LanguageService {
  languages: ITranslateLanguage[] = [
    {
      id: 'en',
      title: 'English',
      flag: 'us',
    },
    {
      id: 'fr',
      title: 'Français',
      flag: 'ca',
    },
  ];
  private readonly DEFAULT_LANG = 'en';
  private readonly KEY_LANG = 'lang';

  constructor(private translateService: TranslateService) {
    this.translateService.addLangs(['fr', 'en']);
    this.translateService.setDefaultLang(this.DEFAULT_LANG);
  }

  init() {
    const { id } = this.getLanguage();
    this.translateService.use(id);
  }

  setLanguage(lang) {
    localStorage.setItem(this.KEY_LANG, lang);
    this.init();
  }

  getLanguage(): ITranslateLanguage {
    const langStr = localStorage.getItem(this.KEY_LANG);
    if (langStr) {
      return this.findLanguage(langStr);
    }
    const { languages, language, browserLanguage, userLanguage } = this.getNavigator();
    let [browserLang] = languages;
    browserLang = browserLang || language || browserLanguage || userLanguage;
    return this.findLanguage(browserLang);
  }

  private findLanguage(langStr) {
    const lang = this.languages.find(({ id }) => id === this.parseLang(langStr));
    if (lang) {
      return lang;
    }
    return this.languages.find(({ id }) => id === this.DEFAULT_LANG);
  }

  private getNavigator(): INavigator {
    const { navigator } = (window || {}) as IWindow;
    if (typeof navigator === 'undefined') {
      return { language: this.DEFAULT_LANG };
    }
    return navigator;
  }

  private parseLang(langStr) {
    let browserLang = langStr;
    if (browserLang.includes('-')) {
      [browserLang] = browserLang.split('-');
    }

    if (browserLang.includes('_')) {
      [browserLang] = browserLang.split('_');
    }

    return browserLang;
  }
}
