import { Injectable } from '@angular/core';
import { LaunchNavigator, LaunchNavigatorOptions } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderBehaviors } from '../../behaviors/translate/translation-loader.behaviors';
import { ICoordinates } from '../../interfaces/geolocation-info.interface';

import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';

@Injectable()
export class ItineraryService {
  constructor(
    private launchNavigator: LaunchNavigator,
    private translationLoaderBehaviors: TranslationLoaderBehaviors,
    private translateService: TranslateService,
  ) {
    this.translationLoaderBehaviors.loadTranslations(english, french);
  }

  async launch(projectCoordinates: ICoordinates) {
    if (Capacitor.isNativePlatform()) {
      const { latitude, longitude } = projectCoordinates;
      const options: LaunchNavigatorOptions = {
        transportMode: 'driving',
        appSelection: {
          cancelButtonText: this.translateService.instant('SERVICE.ITINERARY.NAVIGATOR_CHOICE_CANCEL'),
          dialogHeaderText: this.translateService.instant('SERVICE.ITINERARY.NAVIGATOR_CHOICE_TEXT'),
          rememberChoice: {
            prompt: {
              bodyText: this.translateService.instant('SERVICE.ITINERARY.NAVIGATOR_CHOICE_REMEMBER_TEXT'),
              headerText: this.translateService.instant('SERVICE.ITINERARY.NAVIGATOR_REMEMBER_CHOICE_HEADER'),
              noButtonText: this.translateService.instant('SERVICE.ITINERARY.NAVIGATOR_REMEMBER_CHOICE_NO'),
              yesButtonText: this.translateService.instant('SERVICE.ITINERARY.NAVIGATOR_REMEMBER_CHOICE_YES'),
            },
          },
        },
      };
      await this.launchNavigator.navigate([latitude, longitude], options);
    }
  }
}
