import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

export interface IPushNotificationsRegisterToken {
  registrationId: string;
  language: string;
  deviceId: string;
}

@Injectable()
export class PushNotificationsService {
  constructor(private configService: ConfigService, private http: HttpClient) {}

  registerToken(pushNotificationsRegisterToken: IPushNotificationsRegisterToken) {
    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/push-notifications/register-token`;
    return this.http.post<void>(url, { ...pushNotificationsRegisterToken });
  }

  unregisterDevice(deviceId: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/push-notifications/unregister-token`;
    return this.http.patch<void>(url, { deviceId });
  }
}
