import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PermissionService } from '../services/permission/permission.service';

@Injectable({ providedIn: 'root' })
export class GrantsInterceptor implements HttpInterceptor {
  constructor(private permissionService: PermissionService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { authorizeIds: setHeaders } = this.permissionService.getGrants();

    return next.handle(request.clone({ setHeaders })).pipe(
      catchError((messageError) => {
        if (messageError === 'SERVER.ACTION.ERROR.001') {
          return this.handleResetGrant(request, messageError, next);
        } else if (typeof messageError === 'string') {
          return throwError(messageError);
        }
      }),
    );
  }

  private handleResetGrant(request, messageError, next): Observable<any> {
    if (request.headers.get('grantsRetryRequest')) {
      return throwError(messageError);
    }
    return this.handleUnauthorizedError(next, request, messageError);
  }

  private handleUnauthorizedError(next: HttpHandler, request: HttpRequest<unknown>, messageError): Observable<any> {
    const { url } = request;
    if (url.includes('public/auth/grants')) {
      return throwError(messageError);
    }
    return this.permissionService.fetchGrants().pipe(
      switchMap(() => {
        const { authorizeIds: setHeaders } = this.permissionService.getGrants();
        return next.handle(request.clone({ setHeaders: { ...setHeaders, grantsRetryRequest: 'true' } }));
      }),
    );
  }
}
