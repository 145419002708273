import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IMessageCount } from '@app/core/interfaces/message-count.interface';
import { catchError, flatMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IMessage } from '@app/core/interfaces/message.interface';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class MessagesResolve implements Resolve<IMessage[]> {
  messageCount: BehaviorSubject<IMessageCount> = new BehaviorSubject<IMessageCount>({ total: 0, unread: 0, high: 0 });

  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(): Observable<IMessage[]> {
    return this.getMessages();
  }

  getMessageCount(): Observable<IMessageCount> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IMessageCount>(`${host}${apiUrl}/mobile/messages/count`).pipe(
      flatMap((res) => {
        this.messageCount.next(res);
        return of(res);
      }),
      catchError(() => of({ total: 0, unread: 0, high: 0 })),
    );
  }

  getMessages(): Observable<IMessage[]> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IMessage[]>(`${host}${apiUrl}/mobile/messages`);
  }

  updateMessage(message: IMessage) {
    const { id, status } = message;
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.put(`${host}${apiUrl}/mobile/messages/${id}`, { status });
  }

  createMessage(message: IMessage) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post(`${host}${apiUrl}/mobile/messages`, message);
  }
}
