import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IProject } from '@app/core/interfaces/project-detail.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectsResolve implements Resolve<IProject[]> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProject[]> {
    return this.getProjects();
  }

  getProjects() {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IProject[]>(`${host}${apiUrl}/mobile/projects`);
  }

  getProject(id: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IProject>(`${host}${apiUrl}/mobile/projects/${id}`);
  }
}
