import { Color } from './color';
import { CusColors } from './cus-colors';
import { MatColors } from './mat-colors';

export class ColorTheme {
  readonly color: Color;
  readonly contrast: Color;
  readonly dark: Color;
  readonly light: Color;

  constructor(color, hue?) {
    const isMaterialColor = !!hue;
    if (isMaterialColor) {
      const paletteColor = MatColors.getColor(color);
      this.color = new Color(paletteColor[hue]);
      this.contrast = new Color(paletteColor.contrast[hue]);
      this.light = new Color(paletteColor.light[hue]);
      this.dark = new Color(paletteColor.dark[hue]);
    } else {
      const cusColors = CusColors.getColor(color);
      this.color = new Color(cusColors.color);
      this.contrast = new Color(cusColors.contrast);
      this.light = new Color(cusColors.light);
      this.dark = new Color(cusColors.dark);
    }
  }

  toString() {
    return this.color.toString();
  }

  rgb(): number[] {
    return this.color.rgb();
  }

  equals(o) {
    return this.toString() === o.toString();
  }
}
