import { Injectable } from '@angular/core';
import { noop } from 'rxjs';
import { IRequestCacheItem } from './request-cached-item.interface';
import { NetworksService } from '../networks/networks.service';
import { IUserInformation } from '../../interfaces/user-information.interface';
import { CachingModel } from '../../models/caching.model';
import { SqliteService } from '../sqlite/sqlite.service';
import { IRequestCachedItem } from './request-cache-item.interface';
import { ConfigService } from '../config/config.service';

@Injectable({ providedIn: 'root' })
export class RequestCachingService extends CachingModel {
  private expirationDelay = 15 * 60 * 1000;

  constructor(
    private networksService: NetworksService,
    private configService: ConfigService,
    sqliteService: SqliteService,
  ) {
    super(sqliteService, 'offline', configService);
  }

  async addItem(key: string, content: unknown, tableName: string, userInformation?: IUserInformation): Promise<void> {
    await this.clearCache(key, tableName, userInformation);
    return super.addItem(key, content, tableName, userInformation);
  }

  async getItem(key: string, tableName: string, userInformation?: IUserInformation): Promise<IRequestCacheItem> {
    const params = this.getSelectParams(key, userInformation);
    const whereCondition = this.getSelectWhereCondition(userInformation);

    let data;
    try {
      const sql = `SELECT * FROM ${tableName} WHERE key=? AND ${whereCondition} ORDER BY dateAdded desc`;
      data = await this.database.executeSql(sql, params);
    } catch (error) {
      return undefined;
    }
    const cacheItem = data && (data.rows.item(0) as IRequestCachedItem);

    if (!cacheItem) {
      return undefined;
    }

    return {
      value: JSON.parse(cacheItem.content),
      isExpired: Date.now() - cacheItem.dateAdded > this.expirationDelay,
    };
  }

  async clearCache(key: string, tableName: string, userInformation?: IUserInformation): Promise<void> {
    try {
      const params = this.getSelectParams(key, userInformation);
      const whereCondition = this.getSelectWhereCondition(userInformation);
      await this.database.executeSql(`DELETE FROM ${tableName} WHERE key=? AND ${whereCondition}`, params);
      await this.sqliteService.loggerTables(this.database);
    } catch (e) {}
  }

  async clearCacheAllPunch(): Promise<void> {
    await this.database.executeSql('DELETE FROM punch').catch(noop);
    await this.sqliteService.loggerTable(this.database, 'punch').catch(noop);
    await this.database.executeSql('DELETE FROM geolocation').catch(noop);
    await this.sqliteService.loggerTable(this.database, 'geolocation').catch(noop);
  }

  async createTable(tableName: string): Promise<void> {
    const columns =
      'id INTEGER PRIMARY KEY AUTOINCREMENT, key TEXT, content TEXT, dateAdded INTEGER, userId TEXT, employeeId TEXT, enterpriseId TEXT';
    return this.sqliteService.createTable(this.database, tableName, columns);
  }

  async expiredCache(key: string, tableName: string, userInformation?: IUserInformation): Promise<void> {
    const { isExpired } = (await this.getItem(key, tableName, userInformation)) || {};
    if (isExpired && this.networksService.isOnline) {
      await this.clearCache(key, tableName, userInformation);
    }
  }

  mapCacheItem(key: string, content: unknown, userInformation?: IUserInformation): IRequestCachedItem {
    let cacheItem = {
      key,
      content: JSON.stringify(content),
      dateAdded: Date.now(),
    } as IRequestCachedItem;

    if (userInformation) {
      cacheItem = {
        ...cacheItem,
        userId: userInformation.userId,
        employeeId: userInformation.employeeId,
        enterpriseId: userInformation.enterpriseId,
      };
    }

    return cacheItem;
  }

  private getSelectParams(key: string, userInformation?: IUserInformation): string[] {
    const params = [key];

    if (userInformation) {
      const { userId, employeeId, enterpriseId } = userInformation;
      params.push(userId, employeeId, enterpriseId);
    }

    return params;
  }

  private getSelectWhereCondition(userInformation?: IUserInformation): string {
    const emptyWhereConditions = ['userId IS NULL', 'employeeId IS NULL', 'enterpriseId IS NULL'];
    let whereCondition = `(${emptyWhereConditions.join(' AND ')})`;

    if (userInformation) {
      const whereConditions = ['userId=?', 'employeeId=?', 'enterpriseId=?'];
      whereCondition = `((${whereConditions.join(' AND ')}) OR ${whereCondition})`;
    }

    return whereCondition;
  }
}
