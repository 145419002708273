import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel-title',
  templateUrl: './panel-title.component.html',
})
export class PanelTitleComponent {
  @Input() contentCss;
  @Input() overlapCss;

  constructor() {}
}
