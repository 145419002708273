import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICustomForm } from '@app/core/interfaces/custom-form/custom-form.interface';
import { CustomFormActivationTypeEnum } from '@app/core/interfaces/custom-form/custom-form-activation-type.enum';
import { ICustomFormAnswerViewModel } from '@app/core/interfaces/custom-form-answers/custom-form-answer-view-model.interface';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class CustomFormsResolve implements Resolve<ICustomForm | ICustomFormAnswerViewModel> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<ICustomForm | ICustomFormAnswerViewModel> {
    const { id, customFormAnswerId } = route.params;
    if (customFormAnswerId) {
      return this.getCustomFormWithAnswers(id, customFormAnswerId);
    }
    return this.getCustomForm(id);
  }

  verify(activationType: CustomFormActivationTypeEnum, projectId?: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<ICustomForm>(
      `${host}${apiUrl}/mobile/custom-forms/verify/${activationType}${projectId ? `?projectId=${projectId}` : ''}`,
    );
  }

  getCustomForm(id?: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<ICustomForm>(`${host}${apiUrl}/mobile/custom-forms${id ? `/${id}` : ''}`);
  }

  getCustomFormWithAnswers(id: string, customFormAnswerId: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<ICustomFormAnswerViewModel>(
      `${host}${apiUrl}/mobile/custom-forms/${id}/custom-form-answer/${customFormAnswerId}`,
    );
  }

  getCustomFormLogo(customFormId: string, customFormLogoId: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<string>(`${host}${apiUrl}/mobile/custom-forms/${customFormId}/logo/${customFormLogoId}`);
  }
}
