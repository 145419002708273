import * as ColorLib from 'color';

export class Color {
  private readonly color;

  constructor(color: string) {
    this.color = color;
  }

  toString() {
    return this.color;
  }

  rgb(): number[] {
    try {
      const color = ColorLib(this.color);
      return color['color'];
    } catch (e) {
      return [0, 0, 0];
    }
  }
}
