export function AutoUnsubscribe(constructor) {
  const original = constructor.prototype.ngOnDestroy;
  constructor.prototype.ngOnDestroy = (...args) => {
    Object.keys(this || {}).forEach((element) => {
      const property = this[element];
      if (property && typeof property.unsubscribe === 'function') {
        property.unsubscribe();
      }
    });
    if (original && typeof original === 'function') {
      original.apply(this, args);
    }
  };
}
