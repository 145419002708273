import { Injectable } from '@angular/core';
import { CanActivate, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { ErrorService } from '../services/error/error.service';
import { NetworksService } from '../services/networks/networks.service';
import { AuthenticationService } from '../services/authentication/authentication.service';

export interface INavigation {
  route: string;
  navigationExtras?: NavigationExtras;
}

interface IFlowGuardOptions {
  list: (() => Observable<any>)[];
}

@Injectable()
export class InitialFlowGuard implements CanActivate {
  options: IFlowGuardOptions = { list: [] };

  constructor(
    private router: Router,
    private platform: Platform,
    private networksService: NetworksService,
    private authenticationService: AuthenticationService,
    private errorService: ErrorService,
  ) {}

  async canActivate(): Promise<boolean> {
    if (!this.networksService.isOnline || !this.authenticationService.isAuthenticated()) {
      return true;
    }

    const { list = [] } = this.options;

    const routes =
      (await forkJoin([...list.map((data) => data())])
        .toPromise()
        .catch((error) => {
          this.errorService.checkErrorString(error);
          return [];
        })) || [];

    const routeRedirect = routes.find((route) => typeof route === 'object');

    if (routeRedirect) {
      await this.router.navigate([routeRedirect.route], routeRedirect.navigationExtras);
      return false;
    }
    return true;
  }

  onPauseResume() {
    this.platform.resume.subscribe(async () => {
      await this.networksService.getStatus();
      await this.canActivate();
    });
  }
}
