import { Injectable } from '@angular/core';

@Injectable()
export class IteratorService<T> {
  dataList: T[];
  private index = 0;

  init({ dataList = [], index = 0 }) {
    this.dataList = dataList;
    this.index = index;
  }

  hasNext() {
    return this.index < this.dataList.length - 1;
  }

  hasPrevious() {
    return this.index > 0;
  }

  current() {
    return this.dataList[this.index];
  }

  next() {
    if (this.hasNext()) {
      this.index = this.index + 1;
      return this.current();
    }
    return false;
  }

  previous() {
    if (this.hasPrevious()) {
      this.index = this.index - 1;
      return this.current();
    }
    return false;
  }

  add(data) {
    this.dataList = [...this.dataList, data];
  }
}
