import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from '../../services/config/config.service';

@Injectable()
export class RulesResolve implements Resolve<any> {
  rules = new BehaviorSubject([]);

  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.getLastActiveRules();
  }

  getLastActiveRules() {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<any>(`${host}${apiUrl}/mobile/rules/lastactive`).pipe(
      catchError(() => of({ rules: [] })),
      map((rules) => {
        this.rules.next(rules);
        return rules;
      }),
    );
  }

  getRuleByCategoryName(categoryName: string) {
    return (this.rules?.getValue() || []).find(({ categoryName: cn }) => cn === categoryName) || {};
  }
}
