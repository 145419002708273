import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { IFormSimple } from '@app/core/interfaces/form-simple.interface';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class FormsResolve implements Resolve<IFormSimple[]> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IFormSimple[]> {
    return this.getForms(route.params.type);
  }

  getForms(type: number) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IFormSimple[]>(`${host}${apiUrl}/mobile/forms`);
  }
}
