import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PlatformService } from '../platform/platform.service';
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';

export interface IFirebaseCrashlyticsMessageOptions {
  code?: number;
  domain?: string;
}

interface IFirebaseCrashlyticsContext {
  key: string;
  value: number;
  type: 'string' | 'long' | 'double' | 'boolean' | 'int' | 'float';
}

@Injectable()
export class FirebaseCrashlyticsService {
  constructor(private platform: PlatformService) {}

  init() {
    if (Capacitor.isNativePlatform()) {
      try {
        FirebaseCrashlytics.setEnabled({ enabled: true });
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * Platform: Android/iOS
   * Records a non-fatal report to send to Crashlytics.
   * If automatic data collection is disabled, this method queues up all the reports on a device to send to Crashlytics.
   * @params (android) message - message to record for non-fatal error
   * @params (ios) code - the error code (optional) (default: -1001)
   * @params (ios) domain - a string containing the error domain (optional)
   * @params (ios) message - message to record for non-fatal error
   * @returns none
   */
  async recordException(message, { code, domain }: IFirebaseCrashlyticsMessageOptions = {}) {
    if (Capacitor.isNativePlatform()) {
      try {
        if (await this.platform.isAndroid()) {
          FirebaseCrashlytics.recordException({ message });
        } else if (await this.platform.isIos()) {
          FirebaseCrashlytics.recordException({ message, code, domain });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * Platform: Android/iOS
   * Sets a custom key and value to be associated with subsequent fatal and non-fatal reports.
   * When setting an object value the object is converted to a string.
   * @param keys - a unique key associated to the report
   *        value - a unique value associated to the key (string | number | boolean)
   *        type - type of value ('string' | 'long' | 'double' | 'boolean' | 'int' | 'float')
   * @returns none
   */
  async setContext({ key, type, value }: IFirebaseCrashlyticsContext) {
    if (Capacitor.isNativePlatform()) {
      try {
        FirebaseCrashlytics.setContext({ key, value, type });
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * Platform: Android/iOS
   * Records a user ID (identifier) that's associated with subsequent fatal and non-fatal reports.
   * @param userId - unique identifier
   * @returns none
   */
  setUserId(userId: string) {
    if (Capacitor.isNativePlatform()) {
      FirebaseCrashlytics.setUserId({ userId });
    }
  }
}
