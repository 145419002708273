import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from '../services/permission/permission.service';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router, private permission: PermissionService) {}

  canLoad(route: Route): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPermission(route);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.checkPermission(route.routeConfig);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): boolean {
    return this.checkPermission(childRoute.routeConfig);
  }

  private checkPermission(route) {
    const { data = {} } = route;
    const { can } = data;
    return !can || (can && !this.permission.canList(can));
  }
}
