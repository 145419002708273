import { Injectable } from '@angular/core';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { ConfigService } from '../config/config.service';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { Platform } from '@ionic/angular';
import { addMinutes, differenceInSeconds } from 'date-fns';

export declare type DeployType = 'DOWNLOAD' | 'EXTRACT' | 'RELOAD' | 'INITIAL' | 'INSTALL';

@Injectable({ providedIn: 'root' })
export class DeployService {
  available = false;
  onProcess = false;
  incompatibleUpdateAvailable = false;
  percentDone = 0;
  step = 0;
  type: DeployType = 'INITIAL';
  modeProgressBar: ProgressBarMode = 'determinate';
  countdown = 0;
  private intervalCountdown;
  private lastCheckUpdate = addMinutes(new Date(), -5);

  constructor(public deploy: Deploy, private platform: Platform, private configService: ConfigService) {
    this.onProcessUpdate = this.onProcessUpdate.bind(this);
  }

  async setConfiguration(channelTmp?: string) {
    const { channel: channelConfig, host, appId } = this.configService.deployOptions;
    const channel = channelTmp || channelConfig;
    const options: any = { appId, channel };
    if (host) {
      options.host = host;
    }
    await this.deploy.configure(options);
  }

  async changeToBetaChannel() {
    await this.setConfiguration('Beta');
  }

  isReadyToCheckUpdate() {
    const { graceTimeCheckUpdate } = this.configService.deployOptions;
    const delay = differenceInSeconds(new Date(), this.lastCheckUpdate);
    return delay > graceTimeCheckUpdate;
  }

  async initCheckUpdate() {
    if (this.isReadyToCheckUpdate()) {
      this.lastCheckUpdate = new Date();
      const { incompatibleUpdateAvailable, available } = await this.deploy.checkForUpdate();
      this.available = available;
      this.incompatibleUpdateAvailable = incompatibleUpdateAvailable;
    }
  }

  async performManualUpdate() {
    if (this.onProcess) {
      return;
    }

    this.percentDone = 0;
    this.step = 0;
    this.type = 'INITIAL';

    await this.initCheckUpdate();
    if (this.available) {
      this.platform.resume.subscribe(async () => await this.reloadAppCountdown());
      this.onProcess = true;
      this.step++;
      this.onProcessUpdate('INSTALL', 0);
      await this.deploy.downloadUpdate((progress) => this.onProcessUpdate('INSTALL', progress));
      this.step++;
      this.onProcessUpdate('EXTRACT', 0);
      await this.deploy.extractUpdate((progress) => this.onProcessUpdate('EXTRACT', progress));
      this.step++;
      this.intervalCountdown = this.getIntervalCountdown();
      this.onProcessUpdate('RELOAD', 100);
    }
  }

  async reloadApp() {
    this.countdown = 0;
    this.available = false;
    this.onProcess = false;
    this.incompatibleUpdateAvailable = false;
    await this.deploy.reloadApp();
  }

  private onProcessUpdate(type: DeployType = 'INITIAL', percentDone: number) {
    this.percentDone = percentDone;
    this.type = type;
  }

  private getIntervalCountdown() {
    const { graceTimeApplyUpdate } = this.configService.deployOptions;
    this.countdown = graceTimeApplyUpdate * 1000;
    return setInterval(async () => {
      this.countdown -= 1000;
      await this.reloadAppCountdown();
    }, 1000);
  }

  private async reloadAppCountdown() {
    if (this.countdown <= 0) {
      clearInterval(this.intervalCountdown);
      await this.reloadApp();
    }
  }
}
