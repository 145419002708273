import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IFormPhoto } from '@app/core/interfaces/form-photo.interface';
import { IForm } from '@app/core/interfaces/form.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class FormResolve implements Resolve<IForm> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IForm> {
    const { id } = route.params;
    if (id) {
      return this.getForm(id);
    }
  }

  getForm(id: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IForm>(`${host}${apiUrl}/mobile/forms/${id}`);
  }

  saveForm(form: IForm) {
    const { host, apiUrl } = this.configService.globalOptions;
    if (form.id) {
      return this.http.put(`${host}${apiUrl}/mobile/forms/${form.id}`, form);
    } else {
      return this.http.post(`${host}${apiUrl}/mobile/forms`, form);
    }
  }

  getPhotos(formId: string) {
    if (formId) {
      const { host, apiUrl } = this.configService.globalOptions;
      return this.http.get<IFormPhoto[]>(`${host}${apiUrl}/mobile/forms/${formId}/photos`);
    }
  }

  savePhotos(attachedPhotos, formId?) {
    const { host, apiUrl } = this.configService.globalOptions;
    if (!formId) {
      return this.http.post(`${host}${apiUrl}/mobile/forms/photos`, attachedPhotos);
    } else {
      return this.http.post(`${host}${apiUrl}/mobile/forms/${formId}/photos`, attachedPhotos);
    }
  }

  deletePhotos(attachedPhotoIds: string[] = [], formId) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.request('delete', `${host}${apiUrl}/mobile/forms/${formId}/photos`, {
      body: { ids: attachedPhotoIds },
    });
  }

  generatePdf(formId) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post(`${host}${apiUrl}/mobile/forms/${formId}/pdf/generate`, {});
  }

  getPdf(formId, locale) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get(`${host}${apiUrl}/mobile/forms/${formId}/pdf?locale=${locale}`);
  }

  sendEmail(formId, locale, to) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post(`${host}${apiUrl}/mobile/forms/${formId}/email/${locale}`, { to });
  }

  sign(formId, signature) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post(`${host}${apiUrl}/mobile/forms/${formId}/sign`, { data: signature });
  }

  archive(formId) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.patch(`${host}${apiUrl}/mobile/forms/${formId}/archive`, {});
  }

  recover(formId) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.patch(`${host}${apiUrl}/mobile/forms/${formId}/recover`, {});
  }

  delete(formId) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.delete(`${host}${apiUrl}/mobile/forms/${formId}`);
  }

  getSignatures(formId) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get(`${host}${apiUrl}/mobile/forms/${formId}/signatures`);
  }
}
