import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() isDisabled: boolean;
  @Input() isBusy: boolean;
  @Input() title: string;
  @Input() color = 'primary';
  @Input() buttonType: string;

  // tslint:disable-next-line
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter();

  onClickEvent() {
    this.onClick.emit();
  }
}
