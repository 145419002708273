import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { BehaviorSubject, noop } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PlatformService } from '../../services/platform/platform.service';
import { SnackBarTemplateComponent } from '../../components/snack-bar-template/snack-bar-template.component';
import { PushNotificationsService } from '../../services/push-notifications/push-notifications.service';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Device } from '@capacitor/device';
//import { FCM } from '@capacitor-community/fcm';

@Injectable()
export class PushNotificationsBehaviors {
  isRegistered: boolean;
  snackBarSubject: BehaviorSubject<{ title?: string; message?: string }> = new BehaviorSubject({});

  constructor(
    private translateService: TranslateService,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private router: Router,
    private pushNotificationsService: PushNotificationsService,
    private platformService: PlatformService,
  ) {
    this.onTokenRefresh = this.onTokenRefresh.bind(this);
    this.onActionsNotification = this.onActionsNotification.bind(this);
    this.onReceivedNotification = this.onReceivedNotification.bind(this);
  }

  registration(): void {
    PushNotifications.addListener('registration', this.onTokenRefresh);
  }

  async register() {
    if (!this.isRegistered && Capacitor.isNativePlatform()) {
      if (this.isGranted()) {
        this.isRegistered = true;
        await PushNotifications.register();
        PushNotifications.addListener('pushNotificationReceived', this.onReceivedNotification);
        PushNotifications.addListener('pushNotificationActionPerformed', this.onActionsNotification);
      } else {
        this.snackBarSubject.next({ message: 'PERMISSION.PUSH_NOTIFICATIONS.RESQUEST_PERMISSION' });
        this.snackBar.openFromComponent(SnackBarTemplateComponent, { data: this.snackBarSubject });
      }
    }
  }

  async unregister() {
    // await FCM.deleteInstance();
  }

  async getToken() {
    // const { token } = await FCM.getToken();
    // return token;
  }

  async subscribeTo(topic) {
    // await FCM.subscribeTo({ topic });
  }

  async unsubscribeFrom(topic) {
    // await FCM.unsubscribeFrom({ topic });
  }

  private async onReceivedNotification(notification: PushNotificationSchema) {
    // On Android, when the app is in foreground, the native notification is not shown.
    // Used this hack to trigger it anyway.
    if (await this.platformService.isAndroid()) {
      const { body, title } = notification;

      await LocalNotifications.schedule({
        notifications: [
          {
            title,
            body,
            id: Date.now(),
            actionTypeId: '',
          },
        ],
      });
    }
  }

  private async onActionsNotification({ notification }: ActionPerformed) {
    const { data } = notification;
    if (data.nav) {
      await this.router.navigate([data.nav]);
    }
  }

  private async onTokenRefresh(): Promise<void> {
   // const { uuid } = await Device.getId();
    // const { token } = await FCM.getToken();
    // this.pushNotificationsService
    //   .registerToken({
    //     registrationId: token,
    //     language: this.translateService.currentLang,
    //     deviceId: uuid || 'unknown',
    //   })
    //   .pipe(first())
    //   .subscribe(noop);
  }

  private async isGranted(): Promise<boolean> {
    try {
      const { receive: granted } = (await PushNotifications.requestPermissions()) || {};
      return granted === 'granted';
    } catch (e) {
      return false;
    }
  }
}
