import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StickyElementComponent } from './sticky-element.component';
import { MaterialDesignModule } from '../../3p/material-design.module';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, MaterialDesignModule],
  exports: [StickyElementComponent],
  declarations: [StickyElementComponent],
})
export class StickyElementModule {}
