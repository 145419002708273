import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HideOptions, SplashScreen } from '@capacitor/splash-screen';

@Injectable()
export class SplashscreenService {
  splashScreenActive: BehaviorSubject<boolean>;
  splashScreenHide: BehaviorSubject<boolean>;

  constructor() {
    this.splashScreenActive = new BehaviorSubject<boolean>(true);
    this.splashScreenHide = new BehaviorSubject<boolean>(false);
  }

  public hide(options?: HideOptions) {
    return SplashScreen.hide(options);
  }
}
