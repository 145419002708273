export const locale = {
  lang: 'en',
  data: {
    SERVICE: {
      LOCATION: {
        USER_DENIED_LOCATION_PERMISSION: 'User denied location permission',
        DEVICE_NEED_ACCESS_GPS: 'Please turn on GPS to get location',
      },
    },
  },
};
