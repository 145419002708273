import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ColorTheme } from './entities/color-theme';

interface ITheme {
  [value: string]: {
    primary: ColorTheme;
    secondary: ColorTheme;
    tertiary: ColorTheme;
    success: ColorTheme;
    warning: ColorTheme;
    danger: ColorTheme;
    background: ColorTheme;
    dark: ColorTheme;
    medium: ColorTheme;
    light: ColorTheme;
  };
}

@Injectable()
export class ThemeService {
  themesSubject: BehaviorSubject<string> = new BehaviorSubject('default');

  themes: ITheme = {
    default: {
      primary: new ColorTheme('#e25516'),
      secondary: new ColorTheme('#4b324d'),
      tertiary: new ColorTheme('#4b324d'),
      success: new ColorTheme('green', 500),
      warning: new ColorTheme('orange', 500),
      danger: new ColorTheme('red', 500),
      background: new ColorTheme('grey', 200),
      dark: new ColorTheme('grey', 500),
      medium: new ColorTheme('grey', 300),
      light: new ColorTheme('grey', 100),
    },
    mobilePunchV2: {
      primary: new ColorTheme('#4b324d'),
      secondary: new ColorTheme('#e25516'),
      tertiary: new ColorTheme('#e25516'),
      success: new ColorTheme('green', 500),
      warning: new ColorTheme('orange', 500),
      danger: new ColorTheme('red', 500),
      background: new ColorTheme('grey', 200),
      dark: new ColorTheme('grey', 500),
      medium: new ColorTheme('grey', 300),
      light: new ColorTheme('grey', 100),
    },
    mobilePunchV3: {
      primary: new ColorTheme('#4b324d'),
      secondary: new ColorTheme('#4b324d'),
      tertiary: new ColorTheme('#e25516'),
      success: new ColorTheme('green', 500),
      warning: new ColorTheme('orange', 500),
      danger: new ColorTheme('red', 500),
      background: new ColorTheme('grey', 200),
      dark: new ColorTheme('grey', 500),
      medium: new ColorTheme('grey', 300),
      light: new ColorTheme('grey', 100),
    },
    mobilePunchV4: {
      primary: new ColorTheme('#e25516'),
      secondary: new ColorTheme('#e25516'),
      tertiary: new ColorTheme('#4b324d'),
      success: new ColorTheme('green', 500),
      warning: new ColorTheme('orange', 500),
      danger: new ColorTheme('red', 500),
      background: new ColorTheme('grey', 200),
      dark: new ColorTheme('grey', 500),
      medium: new ColorTheme('grey', 300),
      light: new ColorTheme('grey', 100),
    },
  };

  constructor(@Inject(DOCUMENT) private document: any) {}

  getTheme() {
    const themeName = this.getThemeNameCurrent() || 'default';
    return this.themes[themeName];
  }

  getCSSText() {
    const themeName = localStorage.getItem('themeName');
    if (!themeName) {
      return undefined;
    }
    return this.CSSTextGenerator(this.themes[themeName]);
  }

  reset() {
    localStorage.removeItem('themeName');
    this.init();
  }

  init() {
    let themeName = this.getCSSText();
    if (!themeName) {
      this.setTheme('default');
      themeName = this.getCSSText();
    }
    this.setGlobalCSS(themeName);
  }

  setTheme(themeName) {
    const cssText = this.CSSTextGenerator(this.themes[themeName]);
    this.setGlobalCSS(cssText);
    localStorage.setItem('themeName', themeName);
    this.themesSubject.next(themeName);
  }

  setVariable(name, value) {
    this.document.documentElement.style.setProperty(name, value);
  }

  getThemeNameCurrent() {
    return localStorage.getItem('themeName');
  }

  getThemes() {
    return Object.keys(this.themes).map((name) => ({ name, types: this.themes[name] }));
  }

  CSSTextGenerator(colors) {
    colors = { ...this.themes.default, ...colors };

    const { dark, light, background } = colors as { [value: string]: ColorTheme };
    return `
    --ion-color-base: ${background.light};
    --ion-color-contrast: ${background.dark};
    --ion-background-color: ${background.toString()};
    --ion-text-color: ${dark.light};
    --ion-toolbar-background-color: ${light.light};
    --ion-toolbar-text-color: ${light.dark};
    --ion-item-background-color: ${light.dark};
    --ion-item-text-color: ${light.light};
    ${Object.keys(colors)
      .map((key) => this.getColorIndividualCss(key, colors[key]))
      .join(' ')}
    `;
  }

  getColorIndividualCss(type, colorTheme: ColorTheme) {
    return `
      --ion-color-${type}: ${colorTheme.toString()};
      --ion-color-${type}-rgb: ${colorTheme.rgb().join(', ')};
      --ion-color-${type}-contrast: ${colorTheme.contrast};
      --ion-color-${type}-contrast-rgb: ${colorTheme.contrast.rgb().join(', ')};
      --ion-color-${type}-shade:  ${colorTheme.dark};
      --ion-color-${type}-tint:  ${colorTheme.light};
    `;
  }

  initStatusBar() {
    const colors = this.getTheme();
    this.document.body.style.backgroundColor = colors.secondary.toString();
  }

  private setGlobalCSS(css: string) {
    this.document.documentElement.style.cssText = css;
  }
}
