export const locale = {
  lang: 'en',
  data: {
    PAGE: {
      LOGIN: {
        DESCRIPTION: '',
        TITLE: 'Hello',
        SOU_TITLE: 'sign up to your account',
        FORM: {
          TITLE: 'Authentication',
          USERNAME: 'Email or Phone Number',
          PASSWORD: 'Password',
          SIGN_IN: 'Login',
          FORGOT: 'Forgot password?',
          REMEMBER_ME:'Remember me', 
        },
        CREATE_ENTERPRISE: 'Create my enterprise',
      },
    },
  },
};
