import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import CryptoJS from 'crypto-js';
import { IRequestCacheItem } from '../requests-caching/request-cached-item.interface';
import { IUserInformation } from '../../interfaces/user-information.interface';
import { CachingModel } from '../../models/caching.model';
import { SqliteService } from '../sqlite/sqlite.service';
import { IRequestCachedItem } from '../requests-caching/request-cache-item.interface';
import { ConfigService } from '../config/config.service';

@Injectable()
export class AuthenticationCachingService extends CachingModel {
  private expirationDelay = 14 * 24 * 60 * 60 * 1000; // 14 days
  private readonly TABLE = 'auth';

  constructor(private configService: ConfigService, sqliteService: SqliteService) {
    super(sqliteService, 'auth', configService);
  }

  getDatabaseState(): Observable<boolean> {
    return this.isReady.asObservable();
  }

  async addItem(key: string, content: unknown): Promise<void> {
    return super.addItem(key, content, this.TABLE);
  }

  async getItem(key: string, password?: string): Promise<IRequestCacheItem> {
    let data;
    try {
      data = await this.database.executeSql(`SELECT * FROM ${this.TABLE} WHERE key=? ORDER BY dateAdded desc`, [key]);
      await this.sqliteService.loggerTable(this.database, this.TABLE);
    } catch (error) {
      return undefined;
    }
    const cacheItem = data.rows.item(0) as IRequestCachedItem;

    if (!cacheItem) {
      return undefined;
    }

    let value = JSON.parse(cacheItem.content);
    if (password) {
      const { token } = value;
      try {
        const bytes = CryptoJS.AES.decrypt(token, password);
        value = bytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        return undefined;
      }
    }
    return {
      value,
      isExpired: Date.now() - cacheItem.dateAdded > this.expirationDelay,
    };
  }

  async clearCache(): Promise<void> {
    await this.database.executeSql(`DELETE FROM ${this.TABLE}`);
    await this.sqliteService.loggerTable(this.database, this.TABLE);
  }

  async expiredCache(key: string, tableName: string, userInformation?: IUserInformation): Promise<any> {
    return Promise.resolve(undefined);
  }

  createTable(tableName: string): Promise<void> {
    const columns = 'id INTEGER PRIMARY KEY AUTOINCREMENT, key TEXT, content TEXT, dateAdded INTEGER';
    return this.sqliteService.createTable(this.database, tableName, columns);
  }

  mapCacheItem(key: string, content: unknown): IRequestCachedItem {
    return {
      key,
      content: JSON.stringify(content),
      dateAdded: Date.now(),
    } as IRequestCachedItem;
  }
}
