import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { IAgendaEvent } from '@app/core/interfaces/agenda-event.interface';
import { IEventApi } from '@app/core/interfaces/event-api.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { addHours, addMilliseconds, addMinutes } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AgendaEventDetailResolve implements Resolve<IEventApi<IAgendaEvent>> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IEventApi<IAgendaEvent>> {
    const { eventId } = route.params;
    const startDate = route.queryParams['startDate'];
    const { host, apiUrl } = this.configService.globalOptions;

    return this.http.get<IEventApi<IAgendaEvent>>(`${host}${apiUrl}/mobile/agenda/event/${eventId}`).pipe(
      map((res) => {
        if (res.rrule) {
          const [, startDateRule] = res.rrule.split(';');
          res.start = this.formatDate(startDateRule);

          if (res.duration) {
            const [, hours] = res.start.toISOString().split('T');
            res.start = new Date(`${startDate}T${hours}`);
            res.end = res.start;
            if (typeof res.duration === 'string') {
              const [durHours, durMinutes] = res.duration.split(':');
              res.end = addHours(res.end, Number(durHours));
              res.end = addMinutes(res.end, Number(durMinutes));
            } else {
              res.end = addMilliseconds(res.end, res.duration);
            }
          }
        }
        return res;
      }),
    );
  }

  patch(event) {
    const { id } = event;
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.patch<IEventApi<IAgendaEvent>>(`${host}${apiUrl}/mobile/agenda/event/${id}`, { data: event });
  }

  private formatDate(dateString: string): Date {
    const DATE_SELECTOR_REGEX = /[0-9]{8}T[0-9]{6}/;
    const DATE_FORMATTER_REGEX = /^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})$/g;

    const extractedDate = dateString.match(DATE_SELECTOR_REGEX)[0];
    const formattedDate = extractedDate.replace(DATE_FORMATTER_REGEX, '$1-$2-$3T$4:$5:$6');

    return new Date(formattedDate);
  }
}
