import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermissionService } from '../../services/permission/permission.service';

@Pipe({
  name: 'canNav',
})
export class CanNavPipe implements PipeTransform {
  constructor(private permission: PermissionService, private route: ActivatedRoute) {}

  transform(routeName: string, route?): boolean {
    const routeConfig = this.findConfig(route);
    const page = routeConfig.find((rc) => rc.path === routeName);
    if (!page) {
      return false;
    }
    const { canLoad } = page.data;
    return canLoad.every((role) => {
      const { resource, action, condition } = role;
      return this.permission.can(resource, action, condition);
    });
  }

  private findConfig(route = this.route) {
    return !route.routeConfig.children ? this.findConfig(route.parent) : route.routeConfig.children;
  }
}
