import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'translateUnique' })
export class TranslateUniquePipe implements PipeTransform {
  transform(key: string, prefix: string, uniqueKey: string): string {
    const value = key.toUpperCase();
    const unique = uniqueKey.toUpperCase();
    return value === unique ? `${prefix}.${value}` : key;
  }
}
