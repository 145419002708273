import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ISignature } from '@app/core/interfaces/signature.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class SignatureResolve implements Resolve<any> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.getSignature();
  }

  getSignature() {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<any>(`${host}${apiUrl}/mobile/signature-data`);
  }

  postSignature(signature: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post(`${host}${apiUrl}/mobile/signature`, { data: signature });
  }

  get(signature) {
    const { id } = signature;
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<ISignature>(`${host}${apiUrl}/mobile/signature/${id}`);
  }

  post(signature: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post(`${host}${apiUrl}/mobile/signature`, { data: signature }, { responseType: 'blob' as 'json' });
  }
}
