import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { differenceInSeconds } from 'date-fns';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PromptLoginDialogComponent } from '../dialogs/prompt-login/prompt-login-dialog.component';
import { ConfigService } from '../services/config/config.service';
import { ILoginOptions } from '../interfaces/login-options.interface';

@Injectable()
export class PromptLoginGuard implements CanActivate {
  options: ILoginOptions;

  constructor(
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private matDialog: MatDialog,
  ) {
    this.options = { additionalDataBody: {} };
  }

  async canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Promise<boolean> {
    if (this.getRemainingTime() <= 0) {
      const configDialog: MatDialogConfig = {
        data: { ...this.options },
        panelClass: 'prompt-login-dialog-container',
      };
      return this.matDialog
        .open<PromptLoginDialogComponent, MatDialogConfig>(PromptLoginDialogComponent, configDialog)
        .afterClosed()
        .toPromise();
    }

    return true;
  }

  getRemainingTime() {
    const currentUser = this.authenticationService.currentUserValue;
    if (!currentUser) {
      return 0;
    }
    const delay = differenceInSeconds(new Date(), new Date(currentUser.lastConnection?.serverTime));
    const { loginOptions } = this.configService;
    const delayGuard =
      typeof this.options.delayGuard !== 'undefined' ? this.options.delayGuard : loginOptions.delayGuard;

    const remainingTime = this.getValue(delayGuard) - this.getValue(delay);
    return remainingTime > 0 ? remainingTime : 0;
  }

  private getValue(delay) {
    return Number.isNaN(delay) ? 0 : delay;
  }
}
