import { NgModule } from '@angular/core';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { UserDataSynchronizerService } from './user-data-synchronizer/user-data-synchronizer.service';
import { PushNotificationsService } from './push-notifications/push-notifications.service';
import { ThemeService } from './theme/theme.service';
import { SplashscreenService } from './splashscreen/splashscreen.service';
import { SearchService } from './search/search.service';
import { RequestsQueueSynchronizerService } from './requests-queue-synchronizer/requests-queue-synchronizer.service';
import { PlatformService } from './platform/platform.service';
import { LocationService } from './location/location.service';
import { IteratorService } from './iterator/iterator.service';
import { FirebaseCrashlyticsService } from './firebase-crashlytics/firebase-crashlytics.service';
import { ErrorService } from './error/error.service';
import { BackButtonNativeService } from './back-button-native/back-button-native.service';
import { AuthenticationCachingService } from './authentication-caching/authentication-caching.service';
import { DiagnosticsService } from './diagnostics/diagnostics.service';
import { ItineraryService } from './itinerary/itinerary.service';
import { DownloadService } from './download/download.service';
import { RouterService } from './router/router.service';
import { LanguageService } from './language/language.service';

@NgModule({
  providers: [
    AuthenticationCachingService,
    BackButtonNativeService,
    DiagnosticsService,
    ErrorService,
    FileTransfer,
    FirebaseCrashlyticsService,
    IteratorService,
    ItineraryService,
    DownloadService,
    RouterService,
    LocationService,
    PlatformService,
    PushNotificationsService,
    RequestsQueueSynchronizerService,
    SearchService,
    SplashscreenService,
    ThemeService,
    LanguageService,
    UserDataSynchronizerService,
  ],
})
export class ServicesModule {}
