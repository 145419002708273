import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';

import { Diagnostic } from '@ionic-native/diagnostic/ngx';

import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders } from '@angular/core';

//import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { ServicesModule } from './services/services.module';
import { SnackBarTemplateModule } from './components/snack-bar-template/snack-bar-template.module';
import { CapacitorModule } from './3p/capacitor.module';
import { PipesModule } from './pipes/pipes.module';
import { TemplateDialogModule } from './dialogs/template/template-dialog.module';
import { SwipeCardDialogModule } from './dialogs/swipe-card/swipe-card-dialog.module';
import { ImageDialogModule } from './dialogs/image/image-dialog.module';
import { ConfirmDialogModule } from './dialogs/confirm/confirm-dialog.module';

import { DirectivesModule } from './directives/directives.module';
import { SheetOptionsModule } from './dialogs/sheet-options/sheet-options.module';
import { BehaviorsModule } from './behaviors/behaviors.module';
import { ConfigService, IConfig } from './services/config/config.service';
import { GuardsModule } from './guards/guards.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { DefaultInterceptor } from './interceptors/default.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { GrantsInterceptor } from './interceptors/grants.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ResolverModule } from './resolver/resolver.module';
import { FormatAbbreviationNamePipe } from './pipes/format-abbreviation-name/format-abbreviation-name.pipe';
import { CachingRequestsInterceptor } from './interceptors/caching-requests/caching-requests.interceptor';
import { PromptLoginDialogModule } from './dialogs/prompt-login/prompt-login-dialog.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    HttpClientModule,
    ResolverModule,
    NgPipesModule,
    CapacitorModule,
    ConfirmDialogModule,
    TemplateDialogModule,
    SwipeCardDialogModule,
    ImageDialogModule,
    SheetOptionsModule,
    HttpClientModule,
    BehaviorsModule,
    ServicesModule,
    ResolverModule,
    GuardsModule,
    DirectivesModule,
    PipesModule,
    NgPipesModule,
    PromptLoginDialogModule,
    SnackBarTemplateModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachingRequestsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GrantsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Diagnostic,
    ServicesModule,
    FormatAbbreviationNamePipe
  ],
})
export class CoreModule {}
