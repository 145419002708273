export const locale = {
  lang: 'fr',
  data: {
    SERVICE: {
      LOCATION: {
        USER_DENIED_LOCATION_PERMISSION: 'L\'utilisateur a refusé l\'autorisation de localisation',
        DEVICE_NEED_ACCESS_GPS: 'Veuillez activer le GPS pour obtenir la position',
      },
    },
  },
};
