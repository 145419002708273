import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-CA';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'formatTime' })
export class FormatTimePipe implements PipeTransform {
  lang: string;
  locales = { fr, en };

  constructor(translateService: TranslateService) {
    this.lang = translateService.currentLang;
  }

  transform(time: number, formatStr: string): string {
    try {
      return format(time, formatStr, { locale: this.locales[this.lang] });
    } catch (e) {
      return '--:--:--';
    }
  }
}
