import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';

import { SQLite } from '@ionic-native/sqlite/ngx';

import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { AuthGuard } from './core/guards/auth.guard';
import { InitialFlowGuard } from './core/guards/initial-flow.guard';
import { PermissionGuard } from './core/guards/permission.guard';
import { DeployService } from './core/services/deploy/deploy.service';
import { RouterService } from './core/services/router/router.service';
import { ClockoraCoreModule } from './core/clockora-core.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MessageCountResolve } from './core/resolver/messages/messageCount.resolve';
import { APP_VERSION } from './version';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard, InitialFlowGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      messageCount: MessageCountResolve,
    },
  },
  {
    path: 'parameters',
    loadChildren: () => import('./pages/parameters/parameters.module').then((m) => m.ParametersPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'update',
    loadChildren: () => import('./pages/update/update.module').then((m) => m.UpdatePageModule),
  },
  {
    path: 'requestEnterprise',
    loadChildren: () =>
      import('./pages/request-enterprise/request-enterprise.module').then((m) => m.RequestEnterprisePageModule),
  },
  {
    path: 'createSignature',
    loadChildren: () => import('./pages/create-signature/create-signature.module').then((m) => m.CreateSignatureModule),
  },
  {
    path: 'termsOfUse',
    loadChildren: () => import('./pages/terms-of-use/terms-of-use.module').then((m) => m.TermsOfUsePageModule),
  },
  {
    path: 'passwordRecover',
    loadChildren: () => import('./pages/password-recover/password-recover.module').then((m) => m.PasswordRecoverModule),
    canLoad: [PermissionGuard],
  },
  {
    path: 'questionnaire/:id',
    loadChildren: () => import('./pages/questionnaire/questionnaire.module').then((m) => m.QuestionnaireModule),
  },
  {
    path: 'custom-form/:id',
    loadChildren: () => import('./pages/custom-form/custom-form.module').then((m) => m.CustomFormModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatStepperModule,
    IonicModule.forRoot({
      rippleEffect: true,
      mode: 'md',
    }),
    CoreModule,
    ClockoraCoreModule.forRoot({
      globalOptions: {
        appId: {
          android: 'ca.clockora',
          ios: 'id1183945583',
        },
        apiUrl: environment.apiUrl,
        host: environment.host,
        isProd: environment.production,
        enableCache: true,
        enablePushNotification: true,
      },
      versionOptions: {
        version: APP_VERSION,
        name: 'mobile-version',
      },
      loginOptions: {
        url: 'public/auth/me',
      },
    }),
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: false,
    //  relativeLinkResolution: 'corrected'
    }),
  ],
  exports: [RouterModule, TranslateModule],
  providers: [
    ScreenOrientation,
    StatusBar,
    SplashScreen,
    Deploy,
    DeployService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3000, horizontalPosition: 'center', verticalPosition: 'top' },
    },
    RouterService,
    { provide: SQLite },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
