<div class="fixed-content" slot="fixed">
  <app-panel-title>
    <div *ngIf="options.showLogo" fxLayout="column" fxLayoutAlign="space-around center" slot="header" class="header">
      <div class="btn-back">
        <button (click)="goBack()" style="background-color: transparent;">
          <mat-icon >chevron_left</mat-icon>
        </button>
      </div>
      <span class="back">Back</span>
      <div class="text-justify p-t-16 color-light-text-secondary">
       <h2> {{ 'PAGE.LOGIN.TITLE' | translate }}</h2>
       <p> {{ 'PAGE.LOGIN.SOU_TITLE' | translate }}</p>
      </div>
      <img src="assets/images/ilustration.png" alt="">
    </div>
    <div class="content-container" slot="content">
      <mat-card class="m-t-16 m-h-16" slot="content">
        <mat-card-content>
          <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" fxLayout="column">
            <div *ngIf="options?.infoUsername" class="m-v-16 fs-16 fw-500">{{ options?.infoUsername | translate }}</div>
            <mat-form-field *ngIf="options?.visibleUsername" appearance="outline" fxFlex>
              <mat-label for="username">{{ 'PAGE.LOGIN.FORM.USERNAME' | translate }}</mat-label>
              
              <input autocapitalize="off" autocomplete="off" formControlName="username" matInput type="tel" />
            </mat-form-field>
  
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{ 'PAGE.LOGIN.FORM.PASSWORD' | translate }}</mat-label>
              
              <input
                [type]="!showPassword ? 'password' : 'text'"
                autocapitalize="off"
                autocomplete="off"
                formControlName="password"
                matInput
                name="password" />
            </mat-form-field>
            <div class="row">
              <mat-checkbox *ngIf="options?.enableRememberMe" class="m-b-16" formControlName="rememberMe" ngDefaultControl>
                {{ 'PAGE.LOGIN.FORM.REMEMBER_ME' | translate }}
              </mat-checkbox>
              <div
              *ngIf="options?.enableForgotPassword"
              class="m-t-16 "
              fxLayout="column"
              fxLayoutAlign="space-around center">
              <button (click)="redirect(['passwordRecover'])" fxFill mat-button>
                {{ 'PAGE.LOGIN.FORM.FORGOT' | translate }}
              </button>
            </div>
            </div>
            
            <app-button [isBusy]="loading" [isDisabled]="!loginForm.valid || loading" [title]="'PAGE.LOGIN.FORM.SIGN_IN'">
            </app-button>
            <div *ngIf="error" [appColorOnly]="true" appColor="warning" class="m-t-8 m-b-0">
              {{ error | translate }}
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
   
  </app-panel-title>

 
</div>

