import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class RouterService {
  onPageChanged: BehaviorSubject<{ isOnPageChanged: boolean; type: string; lastPage?: string }>;

  constructor(private router: Router) {
    this.onPageChanged = new BehaviorSubject({ isOnPageChanged: false, type: '' });
    router.events.subscribe((routerEvent: any) => {
      this.checkRouterEvent(routerEvent);
    }) ;

  }

  // constructor(private router: Router) {
  //   this.onPageChanged = new BehaviorSubject({ isOnPageChanged: false, type: '' });

  //   // Subscribe to router events with the correct type
  //   router.events.subscribe((event: Event) => {
  //     // Check if the event is a RouterEvent
  //     if (event instanceof RouterEvent) {
  //       this.checkRouterEvent(event);
  //     }
  //   });
  // }

  checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      const lastPage = this.router.routerState.snapshot.url;
      this.onPageChanged.next({ isOnPageChanged: true, type: NavigationError.name, lastPage });
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.onPageChanged.next({ isOnPageChanged: false, type: NavigationError.name });
    }
  }
}
