import { Injectable, NgZone } from '@angular/core';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { BehaviorSubject, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { flatMap } from 'rxjs';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { DiagnosticsService } from '../diagnostics/diagnostics.service';
import { Platform } from '@ionic/angular';
import { TranslationLoaderBehaviors } from '../../behaviors/translate/translation-loader.behaviors';
import { SnackBarTemplateComponent } from '../../components/snack-bar-template/snack-bar-template.component';
import { IGeolocationInfo } from '../../interfaces/geolocation-info.interface';

import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';

interface IGeolocationPosition {
  timestamp?: number;
  coords?: {
    latitude: number;
    longitude: number;
    accuracy: number;
    altitudeAccuracy?: number;
    altitude?: number;
    speed?: number;
    heading?: number;
  };
  err?: any;
}

export interface ILocationOptions {
  enableHighAccuracy: boolean;
  timeout: number;
  maximumAge: number;
  accuracy: number;
  numberOfTries: number;
}

@Injectable()
export class LocationService {
  positionSubject: BehaviorSubject<IGeolocationPosition>;
  snackBarSubject: BehaviorSubject<string>;
  private readonly DEFAULT_ACCURACY = 9999;
  private readonly BEST_ACCURACY_RANGE_LIMIT = 5;
  private position: IGeolocationPosition;

  constructor(
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translationLoaderBehaviors: TranslationLoaderBehaviors,
    private diagnostic: Diagnostic,
    private diagnosticsService: DiagnosticsService,
    private platform: Platform,
  ) {
    this.translationLoaderBehaviors.loadTranslations(english, french);
    this.positionSubject = new BehaviorSubject({});
    this.snackBarSubject = new BehaviorSubject('');
  }

  async getCurrentPosition(
    options = {
      timeout: 5000,
      accuracy: 300,
      maximumAge: 0,
      numberOfTries: 5,
      enableHighAccuracy: true,
    },
  ): Promise<IGeolocationInfo> {
    return new Promise(async (resolve, reject) => {
      this.position = {};
      let watchIdCurrent;
      let bestPosition: IGeolocationInfo;
      this.positionSubject = new BehaviorSubject({});
      let numberOfTries = options.numberOfTries;
      const sub = this.positionSubject
        .pipe(flatMap((resPosition) => of(this.formatCoordinates(resPosition))))
        // eslint-disable-next-line complexity
        .subscribe(
          // eslint-disable-next-line complexity
          async (resCoordinates) => {
            const {
              coordinates: { accuracy, err },
            } = resCoordinates;
            if (
              (accuracy <= options.accuracy || (!bestPosition && err)) &&
              (!bestPosition || accuracy < bestPosition.coordinates.accuracy)
            ) {
              bestPosition = resCoordinates;
            }
            if (numberOfTries <= 0 || accuracy <= this.BEST_ACCURACY_RANGE_LIMIT || bestPosition?.coordinates.err) {
              this.sendPosition(watchIdCurrent, sub, resolve, bestPosition);
            }
            numberOfTries--;
          },
          (error) => {
            const { coordinates } = bestPosition;
            bestPosition.coordinates = { ...coordinates, err: error };
            this.sendPosition(watchIdCurrent, sub, resolve, bestPosition);
          },
        );

      watchIdCurrent = await this.getMyLocation(options);
    });
  }

  async askToTurnOnGPS(): Promise<boolean> {
    return LocationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
        return LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY)
          .then(() => true)
          .catch(() => false);
      }
      return this.platform.is('ios');
    });
  }

  async checkGPSPermission(): Promise<boolean> {
    if (Capacitor.isNativePlatform()) {
      const { permissionStatus } = this.diagnostic;
      const locationAuthorizationStatus = await this.diagnosticsService.getLocationAuthorizationStatus();
      return (
        locationAuthorizationStatus === permissionStatus.GRANTED ||
        locationAuthorizationStatus === permissionStatus.GRANTED_WHEN_IN_USE
      );
    }
    return true;
  }

  async requestGPSPermission(): Promise<string> {
    if (Capacitor.isNativePlatform()) {
      return await this.diagnosticsService.requestLocationAuthorization();
    }
  }

  async getMyLocation(options: ILocationOptions) {
    if (Capacitor.isNativePlatform()) {
      return await this.checkPermissions(options);
    }
    return this.postGPSPermission(true, options);
  }

  async postGPSPermission(canUseGPS: boolean, options: ILocationOptions) {
    if (canUseGPS) {
      return this.watchPosition(options);
    } else {
      this.snackBarSubject.next('SERVICE.LOCATION.DEVICE_NEED_ACCESS_GPS');
      this.snackBar.openFromComponent(SnackBarTemplateComponent, { data: this.snackBarSubject });
      this.position.err = { MESSAGE: 'DEVICE_NEED_ACCESS_GPS' };
      this.positionSubject.next(this.position);
    }
  }

  async watchPosition({ timeout, maximumAge }: any) {
    let watchId;
    try {
      watchId = Geolocation.watchPosition({ enableHighAccuracy: true, timeout, maximumAge }, (position, err) => {
        this.ngZone.run(async () => {
          if (err) {
            this.position = { ...this.position, err };
            this.positionSubject.next(this.position);
            return;
          }
          this.position = position;
          this.positionSubject.next(this.position);
        });
      });
    } catch (err) {
      this.position.err = { message: 'UNKNOWN_GPS_ERROR', ...err };
      this.positionSubject.error(this.position);
    }
    return watchId;
  }

  async setQuickPosition() {
    if (Capacitor.isNativePlatform()) {
      this.positionSubject.next(await Geolocation.getCurrentPosition());
    }
  }

  async clearWatch(id) {
    await Geolocation.clearWatch({ id });
  }

  private sendPosition(watchIdCurrent: string, sub, resolve, bestPosition: IGeolocationInfo) {
    this.clearWatch(watchIdCurrent);
    setTimeout(() => {
      sub?.unsubscribe();
      resolve(bestPosition);
    });
  }

  private async checkPermissions(options: ILocationOptions) {
    const hasPermission = await this.checkGPSPermission();
    if (hasPermission) {
      const canUseGPS = await this.askToTurnOnGPS();
      return this.postGPSPermission(canUseGPS, options);
    } else {
      const { permissionStatus } = this.diagnostic;
      const permission = await this.requestGPSPermission();
      if (permission === permissionStatus.GRANTED || permission === permissionStatus.GRANTED_WHEN_IN_USE) {
        const canUseGPS = await this.askToTurnOnGPS();
        return this.postGPSPermission(canUseGPS, options);
      } else {
        this.snackBarSubject.next('SERVICE.LOCATION.USER_DENIED_LOCATION_PERMISSION');
        this.snackBar.openFromComponent(SnackBarTemplateComponent, { data: this.snackBarSubject });
        this.position.err = { message: 'USER_DENIED_LOCATION_PERMISSION' };
        this.positionSubject.next(this.position);
      }
    }
  }

  private formatCoordinates(position: IGeolocationPosition): IGeolocationInfo {
    const { coords, timestamp, err } = position;
    const {
      accuracy = this.DEFAULT_ACCURACY,
      altitude,
      altitudeAccuracy,
      heading,
      latitude,
      longitude,
      speed,
    } = coords || {};
    return {
      coordinates: { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed, timestamp, err },
    };
  }
}
