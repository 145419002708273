import { Pipe, PipeTransform } from '@angular/core';
import { PermissionService } from '../../services/permission/permission.service';
import { RoleResourcesEnum } from '../../enums/role-resources.enum';
import { RoleActionsEnum } from '../../enums/role.actions.enum';

@Pipe({
  name: 'can',
})
export class CanPipe implements PipeTransform {
  constructor(private permission: PermissionService) {}

  transform(resource: RoleResourcesEnum, action: RoleActionsEnum | string): boolean {
    return this.permission.can(resource, action);
  }
}
