import { Pipe, PipeTransform } from '@angular/core';
import { format, formatDistance, isBefore, subWeeks } from 'date-fns';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-CA';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'formatTimeDistance' })
export class FormatTimeDistancePipe implements PipeTransform {
  lang: string;
  locales = { fr, en };

  constructor(translateService: TranslateService) {
    this.lang = translateService.currentLang;
  }

  transform(time: number, formatStr: string): string {
    if (!time) {
      return '';
    }
    const limit = subWeeks(new Date(), 1);
    const isBeforeLimit = isBefore(time, limit);
    const dateOptions = { locale: this.locales[this.lang], addSuffix: true };
    if (isBeforeLimit) {
      return format(time, formatStr, dateOptions);
    }
    return formatDistance(time, new Date(), dateOptions);
  }
}
