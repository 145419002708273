import { Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PushNotificationsBehaviors } from '../behaviors/push-notifications/push-notifications.behaviors';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { RequestCachingService } from '../services/requests-caching/request-caching.service';
import { ConfigService } from '../services/config/config.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    @Optional() private pushNotificationsBehaviors: PushNotificationsBehaviors,
    @Optional() private requestCachingService: RequestCachingService,
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      const { enablePushNotification, enableCache } = this.configService.globalOptions;
      if (enablePushNotification) {
        await this.pushNotificationsBehaviors.register();
      }
      if (enableCache) {
        return this.initializeUserCache();
      }
      return Promise.resolve(true);
    }

    await this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  private initializeUserCache(): Promise<boolean> {
    return new Promise((resolve) => {
      this.requestCachingService.isReady.subscribe((isReady) => {
        if (isReady) {
          resolve(true);
        }
      });
    });
  }
}
