import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { IDeviceInfo } from '../../interfaces/device-info.interface';
import { ConfigService } from '../config/config.service';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';

@Injectable()
export class DiagnosticsService {
  private deviceUUID: string;

  constructor(private diagnostic: Diagnostic, private configService: ConfigService) {}

  async getCameraAuthorizationStatus() {
    if (Capacitor.isNativePlatform()) {
      return await this.diagnostic.getCameraAuthorizationStatus();
    }
    return 'GRANTED';
  }

  async isLocationEnabled(): Promise<boolean> {
    if (Capacitor.isNativePlatform()) {
      return await this.diagnostic.isLocationEnabled();
    }
    return true;
  }

  async getLocationAuthorizationStatus(): Promise<string> {
    if (Capacitor.isNativePlatform()) {
      return await this.diagnostic.getLocationAuthorizationStatus();
    }
    return 'GRANTED';
  }

  async requestLocationAuthorization(): Promise<string> {
    if (Capacitor.isNativePlatform()) {
      return await this.diagnostic.requestLocationAuthorization();
    }
    return 'GRANTED';
  }

  async getDeviceInformation(): Promise<IDeviceInfo> {
    const resDevice = await Device.getInfo();
    const { version: appVersion } = Capacitor.isNativePlatform() ? await App.getInfo() : { version: undefined };

    if (!this.deviceUUID) {
      const  uuid  = await Device.getId();
      const { deviceUUID } = this.configService.globalOptions;
      this.deviceUUID = deviceUUID || `${uuid}`;
    }

    if (resDevice) {
      return {
        isVirtual: resDevice.isVirtual,
        manufacturer: resDevice.manufacturer,
        model: resDevice.model,
        platform: resDevice.platform,
        uuid: this.deviceUUID,
        version: resDevice.osVersion,
        appVersion,
        isLocationMocked: false,
      };
    }
  }
}
