import { Component } from '@angular/core';

@Component({
  selector: 'app-sticky-element',
  templateUrl: './sticky-element.component.html',
  styleUrls: ['./sticky-element.component.scss'],
})
export class StickyElementComponent {
  constructor() {}
}
