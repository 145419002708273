<div class="button-container">
  <mat-progress-bar *ngIf="isBusy" class="loader" color="primary" mode="indeterminate"></mat-progress-bar>
  <ng-container [ngSwitch]="buttonType">
    <ng-container *ngSwitchCase="'mat-stroked-button'" [ngTemplateOutlet]="matStrokedBtn"></ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="matRaisedBtn"></ng-container>
  </ng-container>
</div>

<ng-template #matRaisedBtn>
  <button (click)="onClickEvent()" [color]="color" [disabled]="isDisabled" fxFill mat-raised-button>
    {{ title | translate }}
  </button>
</ng-template>

<ng-template #matStrokedBtn>
  <button (click)="onClickEvent()" [color]="color" appColor="{{color}}" [disabled]="isDisabled" fxFill mat-stroked-button>
    {{ title | translate }}
  </button>
</ng-template>
