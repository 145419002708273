import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IQuoteRequestData } from '@app/pages/request-enterprise/request-enterprise.page';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class RequestEnterpriseResolver implements Resolve<any> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return;
  }

  requestEnterpriseMail(quoteData: IQuoteRequestData): Observable<void> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post<void>(`${host}${apiUrl}/public/email/send-quote-request`, quoteData);
  }
}
