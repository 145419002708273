import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { coerce, SemVer } from 'semver';
import { Device } from '@capacitor/device';

@Injectable()
export class PlatformService {
  constructor(public platform: Platform) {}

  ready() {
    return this.platform.ready();
  }

  async isAndroid() {
    return (await Device.getInfo()).platform === 'android';
  }

  async isIos() {
    return (await Device.getInfo()).platform === 'ios';
  }

  async osVersion(): Promise<SemVer> {
    return coerce((await Device.getInfo()).osVersion);
  }
}
