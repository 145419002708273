import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { RouterModule } from '@angular/router';
import { NoDataComponent } from './no-data.component';
import { MaterialDesignModule } from '../../3p/material-design.module';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule, RouterModule, MaterialDesignModule],
  exports: [NoDataComponent],
  declarations: [NoDataComponent],
})
export class NoDataModule {}
