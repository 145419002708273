import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { ServicesModule } from './services/services.module';
import { SnackBarTemplateModule } from './components/snack-bar-template/snack-bar-template.module';
import { CapacitorModule } from './3p/capacitor.module';
import { PipesModule } from './pipes/pipes.module';
import { TemplateDialogModule } from './dialogs/template/template-dialog.module';
import { SwipeCardDialogModule } from './dialogs/swipe-card/swipe-card-dialog.module';
import { ImageDialogModule } from './dialogs/image/image-dialog.module';
import { ConfirmDialogModule } from './dialogs/confirm/confirm-dialog.module';
import { ResolverModule } from './resolver/resolver.module';
import { DirectivesModule } from './directives/directives.module';
import { SheetOptionsModule } from './dialogs/sheet-options/sheet-options.module';
import { BehaviorsModule } from './behaviors/behaviors.module';
import { ConfigService, IConfig } from './services/config/config.service';
import { GuardsModule } from './guards/guards.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { PromptLoginDialogModule } from './dialogs/prompt-login/prompt-login-dialog.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CapacitorModule,
    ConfirmDialogModule,
    TemplateDialogModule,
    SwipeCardDialogModule,
    ImageDialogModule,
    SheetOptionsModule,
    PromptLoginDialogModule,
    HttpClientModule,
    BehaviorsModule,
    ServicesModule,
    ResolverModule,
    GuardsModule,
    DirectivesModule,
    PipesModule,
    NgPipesModule,
    SnackBarTemplateModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  exports: [TranslateModule],
  providers: [Diagnostic, Deploy],
})
export class ClockoraCoreModule {
  static forRoot(config: IConfig): ModuleWithProviders<ClockoraCoreModule> {
    return {
      ngModule: ClockoraCoreModule,
      providers: [ConfigService, { provide: 'config', useValue: config }],
    };
  }
}
