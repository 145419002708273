import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ColorTheme } from '../../services/theme/entities/color-theme';
import { ThemeService } from '../../services/theme/theme.service';

@Directive({
  selector: '[appColor]',
})
export class ColorDirective implements OnInit, OnDestroy {
  @Input() appColor:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'dark'
    | 'medium'
    | 'light';
  @Input() appColorTheme: ColorTheme;
  @Input() appColorOnly: boolean;
  @Input() appColorDisabled: boolean;
  private unsubscribeAll: Subject<string>;

  constructor(private hostElement: ElementRef, private themeService: ThemeService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next('unsubscribe');
    this.unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.themeService.themesSubject.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => this.initColors());
  }

  private initColors() {
    const color = this.appColorTheme || this.themeService.getTheme();
    const colorTheme: ColorTheme = !this.appColorDisabled ? color[this.appColor] : color['dark'];
    if (!this.appColorOnly) {
      this.hostElement.nativeElement.style.backgroundColor = colorTheme;
      this.hostElement.nativeElement.style.color = colorTheme.contrast;
    } else if (this.appColorOnly) {
      this.hostElement.nativeElement.style.color = colorTheme;
    }
  }
}
