import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialDesignModule } from '../../3p/material-design.module';
import { LoginComponentModule } from '../../components/login/login.module';
import { PromptLoginDialogComponent } from './prompt-login-dialog.component';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, MaterialDesignModule, TranslateModule, LoginComponentModule],
  exports: [PromptLoginDialogComponent],
  declarations: [PromptLoginDialogComponent],
})
export class PromptLoginDialogModule {}
