import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../services/config/config.service';

@Injectable({ providedIn: 'root' })
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { version, name } = this.configService.versionOptions;
    request = request.clone({
      setHeaders: {
        [name]: version,
        ...this.getSkipCache(request),
      },
    });
    return next.handle(request);
  }

  private getSkipCache(request: HttpRequest<unknown>) {
    return this.isTranslate(request) ? { 'skip-cache': 'true' } : {};
  }

  private isTranslate(request: HttpRequest<unknown>) {
    return request.url.includes('/assets/i18n');
  }
}
