import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IAttachment } from '@app/core/components/tile-attachment/tile-attachment.component';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class ProjectAttachmentsResolve implements Resolve<IAttachment> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAttachment> {
    const { projectId } = route.params;
    return this.getAttachments(projectId);
  }

  getAttachments(id: string): Observable<IAttachment> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IAttachment>(`${host}${apiUrl}/mobile/projects/${id}/attachments`);
  }
}
