import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class RoutingService {

  private currentUrl = '';

  setRoutingUrl(url: string) {
    if (url) {
      this.currentUrl = url;
    }
  }

  getRoutingUrl(): string {
    return this.currentUrl;
  }
}