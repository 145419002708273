import { NgModule } from '@angular/core';

import { CanNavPipe } from './can-nav/can-nav.pipe';
import { CanPipe } from './can/can.pipe';
import { DateFnsPipe } from './date-fns/date-fns.pipe';
import { FormatAbbreviationNamePipe } from './format-abbreviation-name/format-abbreviation-name.pipe';
import { FormatAddressPipe } from './format-address/format-address.pipe';
import { FormatBytesPipe } from './format-bytes/format-bytes.pipe';
import { FormatFullNamePipe } from './format-full-name/format-full-name.pipe';
import { FormatMillisecondToPipe } from './format-millisecond-to/format-millisecond-to.pipe';
import { FormatTimeDistancePipe } from './format-time-distance/format-time-distance.pipe';
import { FormatTimeDurationPipe } from './format-time-duration/format-time-duration.pipe';
import { FormatTimePipe } from './format-time/format-time.pipe';
import { ReplacePipe } from './replace/replace.pipe';
import { RoundDecimalesPipe } from './round-decimales/round-decimales.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { TranslateUniquePipe } from './translate-unique/translate-unique.pipe';
import { FormatFormTitlePipe } from './format-form-title/format-form-title.pipe';

@NgModule({
  declarations: [
    CanNavPipe,
    CanPipe,
    DateFnsPipe,
    FormatAbbreviationNamePipe,
    FormatAddressPipe,
    FormatBytesPipe,
    FormatFullNamePipe,
    FormatMillisecondToPipe,
    FormatTimeDistancePipe,
    FormatTimeDurationPipe,
    FormatTimePipe,
    ReplacePipe,
    RoundDecimalesPipe,
    SafeHtmlPipe,
    TranslateUniquePipe,
    FormatFormTitlePipe
  ],
  exports: [
    CanNavPipe,
    CanPipe,
    FormatAbbreviationNamePipe,
    FormatAddressPipe,
    FormatBytesPipe,
    DateFnsPipe,
    FormatFullNamePipe,
    FormatTimeDistancePipe,
    FormatTimeDurationPipe,
    FormatTimePipe,
    ReplacePipe,
    RoundDecimalesPipe,
    SafeHtmlPipe,
    TranslateUniquePipe,
    FormatMillisecondToPipe,
    FormatFormTitlePipe
  ],
  providers: [
    CanNavPipe,
    CanPipe,
    FormatAbbreviationNamePipe,
    FormatAddressPipe,
    FormatBytesPipe,
    DateFnsPipe,
    FormatFullNamePipe,
    FormatTimeDistancePipe,
    FormatTimeDurationPipe,
    FormatTimePipe,
    ReplacePipe,
    RoundDecimalesPipe,
    SafeHtmlPipe,
    TranslateUniquePipe,
  ],
})
export class PipesModule {}
