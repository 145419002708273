import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { RecurrenceEnum } from '../../enums/recurrence.enum';
import { ConfigService } from '../../services/config/config.service';

interface IQuestionnaire {
  id: string;
  i18n: any;
  key?: string;
  formIOObject: {
    title?: string;
    display?: string;
    name?: string;
    path?: string;
    type?: string;
    project?: string;
    template?: string;
    components?: any[];
    tags?: string[];
    access?: any[];
    submissionAccess?: any[];
  };
  recurrency: RecurrenceEnum;
  typeEvent: string;
  replacedBy?: string;
}

@Injectable()
export class QuestionnairesResolve implements Resolve<IQuestionnaire[] | IQuestionnaire> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IQuestionnaire[] | IQuestionnaire> {
    console.log("resolve questionnaire")
    const { id } = route.params;
    return this.get(id);
  }

  verify(options: { employeeId?: string } = {}) {
    console.log("questionnaire verify")
    const { employeeId } = options;
    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/questionnaires/verify${employeeId ? `/${employeeId}` : ''}`;
    return this.http.get<IQuestionnaire>(url);
  }

  get(id?) {
    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/questionnaires${id ? `/${id}` : ''}`;
    return this.http.get<IQuestionnaire>(url);
  }

  saveSubmission(submission) {
    const { employeeId } = submission;
    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/questionnaires${employeeId ? `/${employeeId}` : ''}`;
    delete submission.employeeId;
    return this.http.post(url, submission);
  }
}
