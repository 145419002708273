export const locale = {
  lang: 'en',
  data: {
    CORE: {
      DIALOG: {
        PROMPT_LOGIN: {
          BTN: {
            CLOSE: 'Close',
          },
        },
      },
    },
  },
};
