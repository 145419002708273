import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IEmployeeSignature } from '../../interfaces/employee-signature.interface';
import { IEmployeeTermsOfUseInterface } from '../../interfaces/employee-terms-of-use.interface';
import { ConfigService } from '../../services/config/config.service';

@Injectable()
export class EmployeeResolve implements Resolve<any> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.getEmployee();
  }

  getEmployee() {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<any>(`${host}${apiUrl}/mobile/employee`);
  }

  getEmployeeSignature() {
    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/employee/signature`;
    return this.http.get<IEmployeeSignature>(url);
  }

  getEmployeeTermsOfUse() {
    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/employee/termsofuse`;
    return this.http.get<IEmployeeTermsOfUseInterface>(url);
  }

  postEmployeeAcceptTermOfUse() {
    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/employee/termsofuse`;
    return this.http.post<IEmployeeTermsOfUseInterface>(url, { isAccepted: true });
  }
}
