export const locale = {
  lang: 'fr',
  data: {
    SERVICE: {
      ITINERARY: {
        NAVIGATOR_CHOICE_TEXT: `Choisissez l'application`,
        NAVIGATOR_CHOICE_CANCEL: 'Annuler',
        NAVIGATOR_REMEMBER_CHOICE_HEADER: 'Se rappeler mon choix',
        NAVIGATOR_CHOICE_REMEMBER_TEXT: `Voulez-vous toujours utiliser cette application pour obtenir l'itinéraire à l'avenir ?`,
        NAVIGATOR_REMEMBER_CHOICE_YES: 'Oui',
        NAVIGATOR_REMEMBER_CHOICE_NO: 'Non',
      },
    },
  },
};
