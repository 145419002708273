import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConnectionStatus, Network } from '@capacitor/network';

@Injectable({ providedIn: 'root' })
export class NetworksService {
  networkStatusChange: BehaviorSubject<ConnectionStatus>;
  private isNetworkAvailable: boolean;

  constructor() {
    this.networkStatusChange = new BehaviorSubject<ConnectionStatus>({ connected: false, connectionType: 'unknown' });

    Network.addListener('networkStatusChange', (status) => {
      this.isNetworkAvailable = status.connected;
      this.networkStatusChange.next(status);
    });

    this.getStatus().then(() => console.debug('init: Network -> done'));
  }

  get isOnline(): boolean {
    return this.isNetworkAvailable;
  }

  getStatus() {
    return Network.getStatus().then((status) => {
      this.isNetworkAvailable = status.connected;
      this.networkStatusChange.next(status);
      return status;
    });
  }
}
