import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IClient } from '@app/core/interfaces/client.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ClientsResolve implements Resolve<IClient[]> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IClient[]> {
    return this.getClients();
  }

  getClients() {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IClient[]>(`${host}${apiUrl}/mobile/clients`);
  }
}
