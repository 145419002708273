import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { SheetOptionsComponent } from './sheet-options.component';
import { MaterialDesignModule } from '../../3p/material-design.module';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, MaterialDesignModule, TranslateModule],
  exports: [SheetOptionsComponent],
  declarations: [SheetOptionsComponent],
})
export class SheetOptionsModule {}
