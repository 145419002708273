import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatAbbreviationName' })
export class FormatAbbreviationNamePipe implements PipeTransform {
  transform({ lastname = '', firstname = '' }): string {
    const [abbrFirstname = ''] = firstname.trim();
    const [abbrLastname = ''] = lastname.trim();
    if (!abbrFirstname && !abbrLastname) {
      return 'N/A';
    }
    return `${abbrFirstname}${abbrLastname}`.toUpperCase();
  }
}
