import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class UsersResolve {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return;
  }

  updateSkipPasswordReset(skipPasswordReset): Observable<void> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.put<void>(`${host}${apiUrl}/mobile/users/skip-password-reset`, { skipPasswordReset });
  }
}
