import { ApplicationRef, Component, ElementRef, ViewChild } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Capacitor, Plugins } from '@capacitor/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { noop, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomFormActivationTypeEnum } from './core/interfaces/custom-form/custom-form-activation-type.enum';
import { CustomFormsResolve } from './core/resolver/custom-forms/custom-forms.resolve';

import { QuestionnairesResolve } from './core/resolver/questionnaires/questionnaires.resolve';
import { PushNotificationsBehaviors } from './core/behaviors/push-notifications/push-notifications.behaviors';
import { InitialFlowGuard, INavigation } from './core/guards/initial-flow.guard';
import { RulesResolve } from './core/resolver/rules/rules.resolve';
import { AuthenticationService } from './core/services/authentication/authentication.service';
import { BackButtonNativeService } from './core/services/back-button-native/back-button-native.service';
import { FirebaseCrashlyticsService } from './core/services/firebase-crashlytics/firebase-crashlytics.service';
import { LanguageService } from './core/services/language/language.service';
import { NetworksService } from './core/services/networks/networks.service';
import { PlatformService } from './core/services/platform/platform.service';
import { RequestsQueueSynchronizerService } from './core/services/requests-queue-synchronizer/requests-queue-synchronizer.service';
import { SplashscreenService } from './core/services/splashscreen/splashscreen.service';
import { ThemeService } from './core/services/theme/theme.service';
import { UserDataSynchronizerService } from './core/services/user-data-synchronizer/user-data-synchronizer.service';
import { Keyboard } from '@capacitor/keyboard';
import { EmployeeResolve } from './core/resolver/employee/employee.resolve';
import { RoutingService } from './core/services/routing/routing.service';
// const { Keyboard } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('1 => 0', animate('300ms')),
      transition('0 => 1', animate('700ms')),
    ]),
  ],
})
export class AppComponent {
  @ViewChild('splash', { static: false }) splash: ElementRef;
  theme = 'default';
  hiddenAnim = false;
  private subBackButton;

  constructor(
    private themeService: ThemeService,
    private statusBar: StatusBar,
    private router: Router,
    private platformService: PlatformService,
    private initialFlowGuard: InitialFlowGuard,
    private languageService: LanguageService,
    private splashscreenService: SplashscreenService,
    private screenOrientation: ScreenOrientation,
    private backButtonNativeService: BackButtonNativeService,
    private firebaseCrashlyticsService: FirebaseCrashlyticsService,
    private authenticationService: AuthenticationService,
    private pushNotificationsBehaviors: PushNotificationsBehaviors,
    private networksService: NetworksService,
    private rulesResolve: RulesResolve,
    private employeeResolve: EmployeeResolve,
    private questionnairesResolver: QuestionnairesResolve,
    private appRef: ApplicationRef,
    private userDataSynchronizerService: UserDataSynchronizerService,
    private routingService: RoutingService,
    private customFormsResolver: CustomFormsResolve,
    private requestsQueueSynchronizerService: RequestsQueueSynchronizerService,
  ) {
   // this.splashscreenService.splashScreenActive.subscribe((isActive) => (this.hiddenAnim = !isActive));

    const initFlowSteps = [
      this.termsOfUseRoute,
      this.recoverRoute,
      this.rules,
     // this.questionnaireRoute,
      this.signatureRoute,
      this.customFormsRoute,
    ].map((step: () => Observable<INavigation | void>) => step.bind(this));

    this.initialFlowGuard.options.list.push(...initFlowSteps);

    this.platformService.ready().then(async () => {
      this.hideSplashScreen();
      this.languageService.init();
      await this.initializeApp();
      this.initialFlowGuard.onPauseResume();
      this.routerInit();
    });

    let isOnline: boolean;
    this.networksService.networkStatusChange.subscribe(() => {
      if (this.networksService.isOnline !== isOnline) {
        isOnline = this.networksService.isOnline;
        if (isOnline) {
          this.initialFlowGuard.canActivate();
        }

        appRef.tick();
      }
    });
  }

  private async initializeApp() {
    this.statusBar.styleDefault();
    this.themeService.init();
    this.themeService.themesSubject.subscribe((themeName) => (this.theme = themeName));
    if (Capacitor.isNative) {
      this.pushNotificationsBehaviors.registration();
      await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }
    this.userDataSynchronizerService.preloadUserData();
    this.subBackButton = this.backButtonNativeService.onBackButtonNative();
    this.firebaseCrashlyticsService.init();
  }

  private routerInit() {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        await this.onNavigationStart();
      }
      if (event instanceof NavigationEnd) {
        await this.onNavigationEnd(event);
      }
    });
  }

  private async onNavigationStart() {
    if (Capacitor.platform === 'ios') {
      await Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
    this.removeFormioStyle();
  }

  private removeFormioStyle() {
    const currentUrl = this.routingService.getRoutingUrl();
    if (currentUrl.includes('/tabs/questionnaires/questionnaires/')) {
      // REMOVE THIS HACK : Angular Formio is adding a style that breaks other things. The following lines find it and
      // removes it from the head. This is a copy of the hack in 'questionnaires.page.ts' when a user leave the CSST questionnaire
      // from the tab menu.
      const found = [].slice
        .call(document.getElementsByTagName('style'))
        .find((element: HTMLElement) => element.innerText.startsWith('@charset'));
      if (found) {
        document.getElementsByTagName('head')[0].removeChild(found);
      }
      const formioNode = document.querySelector('#formio-stylesheet');
      if (formioNode) {
        document.getElementsByTagName('head')[0].removeChild(formioNode);
      }
    }
  }

  private async onNavigationEnd(event: NavigationEnd) {
    if (Capacitor.isNative) {
      this.themeService.initStatusBar();
    }
    this.routingService.setRoutingUrl(event.url);
  }

  private async hideSplashScreen() {

      setTimeout(() => {
        this.splashscreenService.hide({ fadeOutDuration: 0 });
        this.hiddenAnim = false;
      }, 600);

  }

  private recoverRoute(): Observable<INavigation> {
    const { passwordreset, recoverPassword, skipPasswordReset } = this.authenticationService.currentUserValue.user;
    return of(
      passwordreset || (!skipPasswordReset && !recoverPassword)
        ? {
            route: '/passwordRecover',
            navigationExtras: {
              queryParams: { setRecover: !recoverPassword, showSkip: !(recoverPassword && passwordreset) },
            },
          }
        : undefined,
    );
  }

  private rules(): Observable<void> {
    return this.rulesResolve.getLastActiveRules().pipe(map(noop));
  }

  private questionnaireRoute(): Observable<INavigation> {
    return this.questionnairesResolver
      .verify()
      .pipe(map(({ id }) => (id ? /*{ route: `/questionnaire/${id}` }*/ undefined : undefined)));
  }

  private signatureRoute(): Observable<INavigation> {
    return this.employeeResolve.getEmployeeSignature().pipe(
      map(({ signature }) => {
        if (!signature || signature.isRejected) {
          return { route: '/createSignature' };
        }
      }),
    );
  }

  private customFormsRoute(): Observable<INavigation> {
    return this.customFormsResolver
      .verify(CustomFormActivationTypeEnum.onOneDayReccurence)
      .pipe(map(({ id }) => (id ? { route: `/custom-form/${id}` } : undefined)));
  }

  private termsOfUseRoute(): Observable<INavigation> {
    return this.employeeResolve.getEmployeeTermsOfUse().pipe(
      map(({ isValidTermsOfUse }) => {
        if (!isValidTermsOfUse) {
          return { route: '/termsOfUse' };
        }
      }),
    );
  }
}
