import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { Plugins } from '@capacitor/core';
import { catchError } from 'rxjs';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { APP_VERSION } from '@app/version';
import { IDeviceInfo } from '@app/core/interfaces/device-info.interface';
import { IGeolocationInfo } from '@app/core/interfaces/geolocation-info.interface';
import { IPunchInfo } from '@app/core/interfaces/punch-info.interface';
import { ITimeclock } from '@app/core/interfaces/timeclock.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { LocationService, ILocationOptions } from '@app/core/services/location/location.service';

import { Device } from '@capacitor/device';

export interface IPunchPostResult {
  timeclock?: ITimeclock | any;
  punchInfo: IPunchInfo;
}

@Injectable()
export class PunchInfosResolve implements Resolve<IPunchInfo> {
  constructor(
    private http: HttpClient,
    private locationService: LocationService,
    private configService: ConfigService,
  ) {}

  resolve(): Observable<IPunchInfo> {
    return this.getPunchInfos();
  }

  getPunchInfos() {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IPunchInfo>(`${host}${apiUrl}/mobile/punch`).pipe(catchError(() => of({ punchProjects: [] })));
  }

  punch({ project, task, deviceInfo, status, projectId, taskId, employeeFunctionId, geolocation }) {
    const punchDate = new Date();
    const punch = {
      status,
      deviceInfo,
      employeeFunctionId,
      geolocation,
      projectId: project.projectId,
      taskId: task.taskId,
      activeProjectId: projectId,
      activeTaskId: taskId,
      clientTime: punchDate.valueOf(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      clientTimezoneOffset: punchDate.getTimezoneOffset(),
    };

    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post<IPunchPostResult>(`${host}${apiUrl}/mobile/punch`, punch);
  }

  saveTimeclockGeolocation(id, geolocationInfo) {
    const geolocations = { timeclockId: id, geolocationInfo };
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post<IGeolocationInfo>(`${host}${apiUrl}/mobile/geolocation`, geolocations);
  }

  async getCurrentPosition(options: ILocationOptions): Promise<IGeolocationInfo> {
    return this.locationService.getCurrentPosition(options);
  }

  async getDeviceInfo(): Promise<IDeviceInfo> {
    const resDevice = await Device.getInfo();
    const uuid= await Device.getId();
    if (resDevice) {
      return {
        isVirtual: resDevice.isVirtual,
        manufacturer: resDevice.manufacturer,
        model: resDevice.model,
        platform: resDevice.platform,
        uuid: `${uuid}`,
        version: resDevice.osVersion,
        appVersion: APP_VERSION,
        isLocationMocked: false,
      };
    }
  }
}
