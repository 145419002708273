import { NgModule } from '@angular/core';
import { MaterialDesignModule } from '../../3p/material-design.module';
import { PanelTitleModule } from '../panel-title/panel-title.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { ColorModule } from '../../directives/colors/color.module';
import { FloatingActionButtonModule } from '../floating-action-button/floating-action-button.module';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterialDesignModule,
    PanelTitleModule,
    ReactiveFormsModule,
    FloatingActionButtonModule,
    ColorModule,
    ButtonModule,
  ],
  exports: [LoginComponent],
  declarations: [LoginComponent],
})
export class LoginComponentModule {}
