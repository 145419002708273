import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { IPunchPostResult } from '@app/core/resolver/punch/punch-infos.resolve';
import { StatusEnum } from '@app/core/interfaces/status.enum';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { InterpreterPunchService } from '@app/core/services/interpreter-punch/interpreter-punch.service';
import { NetworksService } from '@app/core/services/networks/networks.service';
import { IRequestCacheItem } from '@app/core/services/requests-caching/request-cached-item.interface';
import { RequestCachingService } from '@app/core/services/requests-caching/request-caching.service';
import { RequestsQueueService } from '@app/core/services/requests-queue/requests-queue.service';

@Injectable({ providedIn: 'root' })
export class CachingRequestsInterceptor implements HttpInterceptor {
  private networkErrorCode = 'SERVER.CONNECTION.ERROR.001';

  constructor(
    private requestCachingService: RequestCachingService,
    private authenticationService: AuthenticationService,
    private networksService: NetworksService,
    private interpreterPunchService: InterpreterPunchService,
    private operationsQueueService: RequestsQueueService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.get('skip-cache')) {
      request = request.clone({ headers: request.headers.delete('skip-cache') });
      return next.handle(request);
    }

    const resourceType = this.getResourceType(request);
    if (!this.isCacheableContent(request, resourceType)) {
      if (!this.networksService.isOnline) {
        return of(new HttpResponse({ body: {} }));
      }
      return next.handle(request);
    }

    return request.method === 'GET'
      ? from(this.interceptGetRequest(request, resourceType, next))
      : this.interceptSaveRequest(request, resourceType, next);
  }

  private getResourceType(request: HttpRequest<unknown>): string {
    const { resourceType } = this.getUrlType(request);
    return resourceType;
  }

  private isCacheableContent(request: HttpRequest<unknown>, resourceType: string): boolean {
    return resourceType === 'punch' || resourceType === 'geolocation' || resourceType === 'projects';
  }

  private async interceptGetRequest(
    request: HttpRequest<unknown>,
    resourceType: string,
    next: HttpHandler,
  ): Promise<HttpEvent<unknown>> {
    const { value: body } = (await this.getCachedContent(request, resourceType)) || {};
    if (body && !this.networksService.isOnline) {
      return new HttpResponse({ body });
    }

    return next
      .handle(request)
      .pipe(
        filter((event) => event instanceof HttpResponse),
        tap((event: HttpResponse<unknown>) => {
          this.cacheContent(request, event, resourceType);
        }),
      )
      .toPromise();
  }

  private async onSaveInterpreterData(dataQueue, data, resourceType: string): Promise<IPunchPostResult> {
    if (!this.networksService.isOnline) {
      if (resourceType === 'punch') {
        return this.interpreterPunchService.punch(dataQueue, data, resourceType);
      } else if (resourceType === 'geolocation') {
        return this.interpreterPunchService.geolocation(dataQueue, data, resourceType);
      }
    }
    return data;
  }

  private interceptSaveRequest(
    request: HttpRequest<any>,
    resourceType: string,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.networksService.isOnline) {
      return next.handle(request).pipe(
        catchError((error) => {
          if (error === this.networkErrorCode) {
            return from(
              this.addToQueue(request, resourceType)
                .then((dataQueue) => {
                  if (resourceType === 'punch') {
                    Object.assign(request.body, {
                      status: request.body.activeProjectId ? StatusEnum.off : StatusEnum.on,
                    });
                  }
                  return this.onSaveInterpreterData(dataQueue, request.body, resourceType);
                })
                .then((body) => new HttpResponse({ body })),
            );
          }
          return of(new HttpResponse({ body: request.body }));
        }),
      );
    }

    return from(
      this.addToQueue(request, resourceType)
        .then((dataQueue) => this.onSaveInterpreterData(dataQueue, request.body, resourceType))
        .then((body) => new HttpResponse({ body })),
    );
  }

  private async getCachedContent(request: HttpRequest<unknown>, resourceType: string): Promise<IRequestCacheItem> {
    const currentUserInformation = this.interpreterPunchService.getCurrentUserInformation();
    return this.requestCachingService.getItem(request.urlWithParams, resourceType, currentUserInformation);
  }

  private async cacheContent(
    request: HttpRequest<unknown>,
    event: HttpResponse<unknown>,
    resourceType: string,
  ): Promise<void> {
    if (Capacitor.isNative) {
      const currentUserInformation = this.interpreterPunchService.getCurrentUserInformation();
      await this.requestCachingService.addItem(request.urlWithParams, event.body, resourceType, currentUserInformation);
    }
  }

  private async addToQueue(request: HttpRequest<unknown>, resourceType: string): Promise<void> {
    const currentUserInformation = this.interpreterPunchService.getCurrentUserInformation();
    return await this.operationsQueueService.addRequest(request, resourceType, currentUserInformation);
  }

  private getUrlType({ url }: HttpRequest<unknown>) {
    const separator = '/';
    const keyFind = `${separator}api${separator}`;
    const indexStart = url.indexOf(keyFind) + keyFind.length;
    const [version, routeType, resourceType] = url.substr(indexStart).split(separator);
    return { version, routeType, resourceType };
  }
}
