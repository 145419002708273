export const locale = {
  lang: 'fr',
  data: {
    PAGE: {
      LOGIN: {
        DESCRIPTION: '',
        TITLE: 'Bonjour',
        SOU_TITLE: 'Inscrivez-vous à votre compte',
        FORM: {
          TITLE: 'Authentification',
          USERNAME: 'Email ou Numéro de téléphone',
          PASSWORD: 'Mot de passe',
          SIGN_IN: 'Connexion',
          FORGOT: 'Mot de passe oublié?',
          REMEMBER_ME: 'Se souvenir de moi',
        },
        CREATE_ENTERPRISE: 'Créer mon entreprise',
      },
    },
  },
};
