import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ILoginOptions } from '../../interfaces/login-options.interface';
import { TranslationLoaderBehaviors } from '../../behaviors/translate/translation-loader.behaviors';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';

@Component({
  templateUrl: './prompt-login-dialog.component.html',
  styleUrls: ['./prompt-login-dialog.component.scss'],
})
export class PromptLoginDialogComponent implements OnInit {
  options: ILoginOptions;

  constructor(
    private dialogRef: MatDialogRef<PromptLoginDialogComponent>,
    private translationLoaderBehaviors: TranslationLoaderBehaviors,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
  ) {
    this.validateLogin = this.validateLogin.bind(this);
    this.translationLoaderBehaviors.loadTranslations(english, french);
  }

  async ngOnInit() {
    this.options = {
      enableUsername: false,
      enableForgotPassword: false,
      enableRequestEnterprise: false,
      enableRememberMe: false,
      callback: this.validateLogin,
      ...this.data,
    };
  }

  validateLogin(isValid) {
    this.dialogRef.close(isValid);
  }
}
