import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-CA';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateFns',
})
export class DateFnsPipe implements PipeTransform {
  lang: string;
  locales = { fr, en };

  constructor(translateService: TranslateService) {
    this.lang = translateService.currentLang;
  }

  transform(date: Date | number, formatStr: string): string {
    return format(new Date(date), formatStr, { locale: this.locales[this.lang] });
  }
}
