import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { IMessageCount } from '@app/core/interfaces/message-count.interface';
import { MessagesResolve } from '@app/core/resolver/messages/messages.resolve';

@Injectable()
export class MessageCountResolve implements Resolve<IMessageCount> {
  constructor(private messagesResolve: MessagesResolve) {}

  resolve(): Observable<IMessageCount> {
    return this.messagesResolve.getMessageCount();
  }
}
