import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { DebugGuard } from './debug.guard';
import { PermissionGuard } from './permission.guard';
import { InitialFlowGuard } from './initial-flow.guard';
import { PromptLoginGuard } from './prompt-login.guard';
import { UpdateGuard } from './update.guard';

@NgModule({
  providers: [AuthGuard, DebugGuard, InitialFlowGuard, PermissionGuard, PromptLoginGuard, UpdateGuard],
})
export class GuardsModule {}
