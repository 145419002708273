import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DeployService } from '../services/deploy/deploy.service';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class UpdateGuard implements CanActivate {
  constructor(private router: Router, private deployService: DeployService) {}

  async canActivate(): Promise<boolean> {
    if (Capacitor.isNativePlatform()) {
      const { onProcess, incompatibleUpdateAvailable } = this.deployService;
      if (!onProcess) {
        await this.deployService.initCheckUpdate();

        if (incompatibleUpdateAvailable) {
          const { route } = await this.getRouteUpdate();
          if (route) {
            await this.router.navigate([route]);
            return false;
          }
        }
      }
    }
    return true;
  }

  private async getRouteUpdate() {
    const { available } = await this.deployService;
    return available ? { route: '/update' } : {};
  }
}
