import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TimeIntervalEnum } from '@app/core/enums/time-interval.enum';
import { IAgendaEvent } from '@app/core/interfaces/agenda-event.interface';
import { IEventApi } from '@app/core/interfaces/event-api.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, add } from 'date-fns';

@Injectable()
export class AgendaResolve implements Resolve<{ date: number; data: IEventApi<IAgendaEvent>[] }> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<{ date: number; data: IEventApi<IAgendaEvent>[] }> {
    return this.getRequest(route);
  }

  getRequest(route: ActivatedRouteSnapshot) {
    const interval: TimeIntervalEnum = route.queryParams['interval'] || TimeIntervalEnum.month;

    const { startOfInterval, endOfInterval } = this.getStartEndOfInterval(interval);
    let from = startOfInterval(
      route.queryParams['from'] ? new Date(route.queryParams['from']) : new Date(),
    ).toISOString();
    let to = endOfInterval(route.queryParams['to'] ? new Date(route.queryParams['to']) : new Date(from)).toISOString();

    if (Number(interval) === TimeIntervalEnum.month) {
      from = startOfWeek(new Date(from)).toISOString();
      to = endOfWeek(add(new Date(to), { weeks: 1 })).toISOString();
    }

    const params = {
      from,
      to,
    };

    const { host, apiUrl } = this.configService.globalOptions;
    return this.http
      .get<IEventApi<IAgendaEvent>[]>(`${host}${apiUrl}/mobile/agenda/events`, { params })
      .toPromise()
      .then((data) => ({ date: new Date().getTime(), data }));
  }

  getStartEndOfInterval(interval: TimeIntervalEnum) {
    switch (Number(interval)) {
      case TimeIntervalEnum.day: {
        return { startOfInterval: startOfDay, endOfInterval: endOfDay };
      }
      case TimeIntervalEnum.week: {
        return { startOfInterval: startOfWeek, endOfInterval: endOfWeek };
      }
      default: {
        return { startOfInterval: startOfMonth, endOfInterval: endOfMonth };
      }
    }
  }
}
