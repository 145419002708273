import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FirebaseCrashlyticsService } from '../firebase-crashlytics/firebase-crashlytics.service';
import { ISignature } from '../../interfaces/signature.interface';
import { ConfigService } from '../config/config.service';
import { StatusEnum } from '../../interfaces/status.enum';
import { RoleTypesEmployeeEnum } from '../../enums/role-types-employee.enum';
import { Capacitor } from '@capacitor/core';
import { PushNotificationsService } from '../push-notifications/push-notifications.service';
import { Device } from '@capacitor/device';

interface ITablet {
  deviceUUID: string;
  status: StatusEnum;
  enterpriseId?: string;
  employeeId?: string;
  userId?: string;
  projectIds: string[];
}

export interface ITokenUser {
  user: IUser;
  employee?: IEmployee;
  enterprise?: IEnterpriseAuth;
  tablet?: ITablet;
  token?: string;
  lastConnection: {
    serverTime: number;
    version: string;
  };
}

export interface IUser {
  id?: string;
  roleAc?: string;
  username?: string;
  lastname?: string;
  firstname?: string;
  passwordreset?: boolean;
  skipPasswordReset?: boolean;
  signature?: ISignature;
  recoverPassword?: {
    sms?: IVerificationCheckResource;
    email?: IVerificationCheckResource;
    call?: IVerificationCheckResource;
    [value: string]: IVerificationCheckResource;
  };
}

interface IVerificationCheckResource {
  to?: string;
  status?: 'approved' | 'pending' | 'canceled' | string;
  channel?: 'sms' | 'email' | 'call';
  code?: string;
  serverTime?: number;
}

interface IEmployee {
  id?: string;
  enterpriseId?: string;
  employeeNumber?: string;
  lastname?: string;
  firstname?: string;
  roleAc?: string;
  status?: number;
}

export interface IEnterpriseAuth {
  name?: string;
}

export interface IOptionsOnLogin {
  url?: string;
  enableInitSession?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  currentUser: Observable<ITokenUser>;
  currentUserSubject: BehaviorSubject<ITokenUser>;
  private jwtHelperService: JwtHelperService = new JwtHelperService();

  constructor(
    private http: HttpClient,
    private router: Router,
    private configService: ConfigService,
    private pushNotificationsService: PushNotificationsService,
    public firebaseCrashlyticsService: FirebaseCrashlyticsService,
  ) {
    this.currentUserSubject = new BehaviorSubject<ITokenUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  get currentUserValue(): ITokenUser {
    return this.currentUserSubject.value;
  }

  isAuthenticated() {
    return !!this.currentUserSubject.value;
  }

  isEmployee(): boolean {
    return this.currentUserSubject.value.employee.roleAc === RoleTypesEmployeeEnum.EMPLOYEE;
  }

  login(data, options: IOptionsOnLogin = {}): Promise<boolean> {
    const { globalOptions, loginOptions } = this.configService;
    const url = options.url || loginOptions.url;
    const { host, apiUrl } = globalOptions;
    return this.http
      .post<{ token: string }>(`${host}${apiUrl}/${url}`, data, { params: loginOptions.params })
      .toPromise()
      .then(({ token }) => {
        this.initSession(token, options);
        return true;
      })
      .catch(() => Promise.reject(false));
  }

  changePassword(body: { oldPassword?: string; newPassword: string }, options: IOptionsOnLogin = {}): Observable<void> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http
      .put<{ token: string }>(`${host}${apiUrl}/mobile/users/password`, body)
      .pipe(map(({ token }) => this.initSession(token, options)));
  }

  passwordWasReset() {
    if (this.isAuthenticated()) {
      const { user, ...otherProps } = this.currentUserSubject.value;
      const tokenUserModel: ITokenUser = {
        ...otherProps,
        user: {
          ...user,
          passwordreset: false,
          recoverPassword: {},
        },
      };
      localStorage.setItem('currentUser', JSON.stringify(tokenUserModel));
      this.currentUserSubject.next(tokenUserModel);
    }
  }

  async logout() {
    if (Capacitor.isNativePlatform()) {
      const  uuid  = await Device.getId();
      this.pushNotificationsService.unregisterDevice(`${uuid}` || 'unknown');
    }
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return this.router.navigate(['/login']);
  }

  getExpiration() {
    const { token } = JSON.parse(localStorage.getItem('currentUser'));
    return this.jwtHelperService.isTokenExpired(token);
  }

  getTokenUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  initSession(token, options: IOptionsOnLogin = {}) {
    const { enableInitSession = true } = options;
    if (enableInitSession) {
      const { user, employee, enterprise, tablet, lastConnection } = this.jwtHelperService.decodeToken(token);
      const tokenUserModel: ITokenUser = { user, employee, enterprise, tablet, token, lastConnection };
      const { id: userId } = user;
      const { id: employeeId } = employee;
      localStorage.setItem('currentUser', JSON.stringify(tokenUserModel));
      this.currentUserSubject.next(tokenUserModel);
      this.firebaseCrashlyticsService.setUserId(employeeId || userId);
    }
  }
}
