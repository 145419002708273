import { NgModule } from '@angular/core';
import { FormsResolve } from '@app/core/resolver/forms/forms.resolve';
import { MessagesResolve } from '@app/core/resolver/messages/messages.resolve';
import { ClientsResolve } from '@app/core/resolver/clients/clients.resolve';
import { AgendaEventCommentsResolve } from '@app/core/resolver/agenda-event-detail/agenda-event-comments.resolve';
import { ProjectsResolve } from '@app/core/resolver/projects/projects.resolve';
import { TimesheetResolve } from '@app/core/resolver/timesheet/timesheet.resolve';
import { EmployeeResolve } from '@app/core/resolver/employee/employee.resolve';
import { SignatureResolve } from '@app/core/resolver/signature/signature.resolve';
import { FormResolve } from '@app/core/resolver/form/form.resolve';
import { AgendaEventDetailResolve } from '@app/core/resolver/agenda-event-detail/agenda-event-detail.resolve';
import { AgendaResolve } from '@app/core/resolver/agenda/agenda.resolve';
import { TimeclocksResolve } from '@app/core/resolver/timeclocks/timeclocks.resolve';
import { ProjectAttachmentsResolve } from '@app/core/resolver/projects/project-attachments.resolve';
import { MaterialsResolve } from '@app/core/resolver/form/materials.resolve';
import { MessageCountResolve } from '@app/core/resolver/messages/messageCount.resolve';
import { ProjectDetailResolve } from '@app/core/resolver/projects/project-detail.resolve';
import { PunchInfosResolve } from '@app/core/resolver/punch/punch-infos.resolve';
import { RequestEnterpriseResolver } from '@app/core/resolver/request-enterprise/request-enterprise.resolver';
import { UsersResolve } from '@app/core/resolver/users/users.resolve';
import { CustomFormsResolve } from '@app/core/resolver/custom-forms/custom-forms.resolve';
import { CustomFormAnswersResolve } from './custom-forms/custom-form-answers.resolve';
import { WaitResolve } from './utils/wait.resolve';
import { QuestionnairesResolve } from './questionnaires/questionnaires.resolve';
import { RulesResolve } from './rules/rules.resolve';

@NgModule({
  providers: [
    MessagesResolve,
    MessageCountResolve,
    PunchInfosResolve,
    ProjectDetailResolve,
    TimeclocksResolve,
    ProjectAttachmentsResolve,
    FormsResolve,
    TimesheetResolve,
    AgendaResolve,
    FormResolve,
    MaterialsResolve,
    RulesResolve,
    ProjectsResolve,
    ClientsResolve,
    QuestionnairesResolve,
    AgendaEventDetailResolve,
    AgendaEventCommentsResolve,
    EmployeeResolve,
    SignatureResolve,
    RequestEnterpriseResolver,
    UsersResolve,
    CustomFormsResolve,
    CustomFormAnswersResolve,
  ],
})
export class ResolverModule {}
