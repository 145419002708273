import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { RouterModule } from '@angular/router';
import { ColorModule } from '../../directives/colors/color.module';
import { FloatingActionButtonComponent } from './floating-action-button.component';
import { IosModule } from '../../directives/ios/ios.module';
import { MaterialDesignModule } from '../../3p/material-design.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    MaterialDesignModule,
    TranslateModule,
    ColorModule,
    RouterModule,
    IosModule,
  ],
  exports: [FloatingActionButtonComponent],
  declarations: [FloatingActionButtonComponent],
})
export class FloatingActionButtonModule {}
