import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { BehaviorSubject, noop } from 'rxjs';
import { IUserInformation } from '../../interfaces/user-information.interface';
import { SqliteService } from '../sqlite/sqlite.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { IQueuedRequest } from './queued-request.interface';

@Injectable({ providedIn: 'root' })
export class RequestsQueueService {
  private database: SQLiteObject;
  private isReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private sqliteService: SqliteService, private authenticationService: AuthenticationService) {
    this.sqliteService.initDatabase('requests-queue').then((database) => {
      this.database = database;
      this.isReady.next(true);
    });
  }

  async addRequest(request: HttpRequest<unknown>, tableName: string, userInformation: IUserInformation): Promise<void> {
    await this.createTable(tableName);

    const queueItem = {
      requestBody: JSON.stringify(request.body),
      requestUrl: request.url,
      date: Date.now(),
      ...userInformation,
    };

    return this.sqliteService.insert(this.database, tableName, queueItem);
  }

  // TODO: Allow more specific retrieval of queued requests
  // - by user that queued the request;
  async getQueuedRequests(resourceType: string): Promise<IQueuedRequest[]> {
    let data: any;
    try {
      data = await this.database.executeSql(`SELECT * FROM ${resourceType} ORDER BY date desc`, []);
    } catch (error) {
      return undefined;
    }

    if (!data) {
      return undefined;
    }

    return this.sqliteService.getDataList(data);
  }

  async getQueuedRequestsCurrentUser(resourceType: string): Promise<IQueuedRequest[]> {
    const currentUser = this.getCurrentUserInformation();
    let data;
    try {
      const params = this.getSelectParams(currentUser);
      const whereCondition = this.getSelectWhereCondition(currentUser);
      const sql = `SELECT * FROM ${resourceType} WHERE ${whereCondition} ORDER BY date asc`;
      data = await this.database.executeSql(sql, params);
    } catch (error) {
      return undefined;
    }

    if (!data) {
      return undefined;
    }

    return this.sqliteService.getDataList(data);
  }

  // TODO: Allow more specific queue clearing.
  // - list of ids;
  // - by user that queued the request;
  clearRequestQueue(resourceType: string, dateLimit?: number): Promise<void> {
    try {
      let query = `DELETE FROM ${resourceType}`;
      if (dateLimit) {
        query = `${query} WHERE date < ${dateLimit}`;
      }
      return this.database.executeSql(query, []);
    } catch (error) {
      return Promise.reject();
    }
  }

  clearRequestQueueById(resourceType: string, ids: number[]): Promise<void> {
    try {
      const query = `DELETE FROM ${resourceType} WHERE id in (${ids.join(', ')})`;
      return this.database.executeSql(query, []);
    } catch (error) {
      return Promise.reject();
    }
  }

  getCurrentUserInformation(): IUserInformation {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      const { user } = currentUser;
      const { employee } = currentUser;

      return {
        userId: user.id,
        employeeId: employee?.id,
        enterpriseId: employee?.enterpriseId,
      };
    }
  }

  async clearCacheAllPunch(): Promise<void> {
    await this.database.executeSql('DELETE FROM punch').catch(noop);
    await this.sqliteService.loggerTable(this.database, 'punch').catch(noop);
    await this.database.executeSql('DELETE FROM geolocation').catch(noop);
    await this.sqliteService.loggerTable(this.database, 'geolocation').catch(noop);
  }

  private createTable(tableName: string): Promise<void> {
    const columns =
      'id INTEGER PRIMARY KEY AUTOINCREMENT, requestBody TEXT, requestUrl TEXT, date INTEGER, userId TEXT, employeeId TEXT, enterpriseId TEXT';
    return this.sqliteService.createTable(this.database, tableName, columns);
  }

  private getSelectWhereCondition(userInformation?: IUserInformation): string {
    const emptyWhereConditions = ['userId IS NULL', 'employeeId IS NULL', 'enterpriseId IS NULL'];
    let whereCondition = `(${emptyWhereConditions.join(' AND ')})`;

    if (userInformation) {
      const whereConditions = ['userId=?', 'employeeId=?', 'enterpriseId=?'];
      whereCondition = `((${whereConditions.join(' AND ')}) OR ${whereCondition})`;
    }

    return whereCondition;
  }

  private getSelectParams(userInformation?: IUserInformation): string[] {
    const params = [];

    if (userInformation) {
      const { userId, employeeId, enterpriseId } = userInformation;
      params.push(userId, employeeId, enterpriseId);
    }

    return params;
  }
}
