import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { PermissionService } from '../permission/permission.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { DocumentType } from '../../enums/document-types.enum';
import { ConfigService } from '../config/config.service';
import { Directory, GetUriResult, WriteFileResult } from '@capacitor/filesystem';
import { Plugins } from '@capacitor/core';
const { Filesystem } = Plugins;

@Injectable()
export class DownloadService {
  constructor(
    private platform: Platform,
    private myDomSanitizer: DomSanitizer,
    private http: HttpClient,
    private transfer: FileTransfer,
    private configService: ConfigService,
    private permissionService: PermissionService,
    private authenticationService: AuthenticationService,
  ) {}

  download(url: string) {
    return this.http.get<{ base64: string }>(url).toPromise();
  }

  async downloadBlob(url: string, name: string, folder: Directory) {
    const fileTransfer: FileTransferObject = this.transfer.create();

    const { authorizeIds: grantHeaders } = this.permissionService.getGrants();
    const { token } = this.authenticationService.currentUserValue;

    const fileUrl = (await this.getUri(name, folder)).uri;

    const { version, name: nameApp } = this.configService.versionOptions;

    const entry = await fileTransfer.download(url, fileUrl, false, {
      headers: {
        ...grantHeaders,
        [nameApp]: version,
        Authorization: `Bearer ${token}`,
      },
    });

    return entry.toURL();
  }

  async getUri(path, directory = Directory.Documents): Promise<GetUriResult> {
    return Filesystem.getUri({ path, directory });
  }

  async fileWrite(path, data): Promise<WriteFileResult> {
    return Filesystem.writeFile({
      path,
      data,
      directory: Directory.Documents,
      recursive: true,
    });
  }

  async isExist(path, directory = Directory.Documents) {
    try {
      return !!(await Filesystem.stat({ path, directory }));
    } catch (e) {
      return Promise.resolve(false);
    }
  }

  getFileType(filename = '', type?) {
    let [, ext] = filename.split('.');
    if (!ext && type) {
      [, ext] = type.split('/');
    }
    if (this.isImage(ext)) {
      return DocumentType.image;
    } else if (this.isVideo(ext)) {
      return DocumentType.video;
    }

    return this.getTypeApplication(ext);
  }

  private getTypeApplication(type) {
    if (this.isWord(type)) {
      return DocumentType.word;
    } else if (this.isSpreadsheet(type)) {
      return DocumentType.spreadsheet;
    } else if (this.isPdf(type)) {
      return DocumentType.pdf;
    }
  }

  private isPdf(type) {
    return type === 'pdf';
  }

  private isWord(type) {
    return type === 'docx' || type === 'doc';
  }

  private isSpreadsheet(type) {
    return type === 'xls' || type === 'xlsx';
  }

  private isImage(type) {
    return type === 'gif' || type === 'png' || type === 'jpg' || type === 'jpeg';
  }

  private isVideo(type) {
    return type === 'mov' || type === 'mpeg' || type === 'mpg' || type === 'avi';
  }
}
