import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { MaterialDesignModule } from '../../3p/material-design.module';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, MaterialDesignModule, TranslateModule],
  exports: [ConfirmDialogComponent],
  declarations: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
