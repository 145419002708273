import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomFormAnswerTypeEnum } from '@app/core/interfaces/custom-form-answers/custom-form-answer-types.enum';
import { ICustomFormAnswerViewModel } from '@app/core/interfaces/custom-form-answers/custom-form-answer-view-model.interface';
import { ICustomForm } from '@app/core/interfaces/custom-form/custom-form.interface';
import { CustomFormSignatureTypeEnum } from '@app/core/interfaces/custom-form-answers/custom-form-signature-type.enum';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class CustomFormAnswersResolve implements Resolve<ICustomFormAnswerViewModel[]> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICustomFormAnswerViewModel[]> {
    const { key } = route.params;
    const { answerType } = route.queryParams;
    return this.getCustomFormAnswers(key, answerType);
  }

  getCustomFormAnswers(key: string, answerType: CustomFormAnswerTypeEnum): Observable<ICustomFormAnswerViewModel[]> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<ICustomFormAnswerViewModel[]>(
      `${host}${apiUrl}/mobile/custom-forms/${key}/custom-form-answers/${answerType}`,
    );
  }

  saveCustomFormAnswer(
    customForm: ICustomForm,
    answerType: CustomFormAnswerTypeEnum,
    customFormAnswerId?: string,
  ): Observable<ICustomFormAnswerViewModel> {
    const { host, apiUrl } = this.configService.globalOptions;
    const baseRequest = `${host}${apiUrl}/mobile/custom-form-answers`;
    if (customFormAnswerId) {
      return this.http.put<ICustomFormAnswerViewModel>(
        `${baseRequest}/${customFormAnswerId}/${answerType}`,
        customForm,
      );
    }
    return this.http.post<ICustomFormAnswerViewModel>(`${baseRequest}/${answerType}`, customForm);
  }

  sign(
    customFormAnswerId: string,
    signature: string,
    signatureType: CustomFormSignatureTypeEnum,
    answerType?: CustomFormAnswerTypeEnum,
  ): Observable<ICustomFormAnswerViewModel> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post<ICustomFormAnswerViewModel>(
      `${host}${apiUrl}/mobile/custom-form-answers/${customFormAnswerId}/sign`,
      {
        data: signature,
        signatureType,
        answerType,
      },
    );
  }

  getSignatures(customFormAnswerId: string): Observable<{ signatureEmployee: string; signatureClient: string }> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<{ signatureEmployee: string; signatureClient: string }>(
      `${host}${apiUrl}/mobile/custom-form-answers/${customFormAnswerId}/signatures`,
    );
  }

  sendEmail(customFormAnswerId: string, emails: string[]) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.post(`${host}${apiUrl}/mobile/custom-form-answers/${customFormAnswerId}/email`, { emails });
  }

  getPDF(customFormAnswerId: string): Observable<{ base64: string }> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<{ base64: string }>(`${host}${apiUrl}/mobile/custom-form-answers/${customFormAnswerId}/pdf`);
  }

  deleteCustomFormAnswer(customFormAnswerId: string): Observable<ICustomFormAnswerViewModel[]> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.delete<ICustomFormAnswerViewModel[]>(
      `${host}${apiUrl}/mobile/custom-form-answers/${customFormAnswerId}`,
    );
  }
}
