import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe
@Component({
  templateUrl: './snack-bar-template.component.html',
})
export class SnackBarTemplateComponent implements OnInit {
  message = '';
  title = '';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: BehaviorSubject<string | { title: string; message: string }>) {}

  ngOnInit(): void {
    this.data.subscribe((data) => {
      if (typeof data === 'object') {
        this.message = data.message;
        this.title = data.title;
      } else {
        this.message = data;
      }
    });
  }
}
