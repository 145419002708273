import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { format } from 'date-fns';
import { HttpClient } from '@angular/common/http';
import { ITimesheetViewModel } from '@app/core/interfaces/timesheet-viewmodel.interface';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class TimesheetResolve implements Resolve<any> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const { date = format(new Date(), 'yyyy-MM-dd') } = route.queryParams;
    return this.getTimesheet(date);
  }

  getTimesheet(date: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<ITimesheetViewModel[]>(`${host}${apiUrl}/mobile/timesheet/${date}`);
  }
}
