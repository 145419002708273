import { BehaviorSubject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarTemplateComponent } from '../../components/snack-bar-template/snack-bar-template.component';

@Injectable()
export class ErrorService {
  errorDetailsSubject: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private snackBar: MatSnackBar) {}

  // eslint-disable-next-line complexity
  checkErrorString(error) {
    if (typeof error !== 'string' && !error.code) {
      return false;
    }

    if (error.code) {
      error = error.code;
    }

    if (error.includes('server.')) {
      const [, msgCode] = error.split('server.');
      const msg = `server.${msgCode}`.trim().toUpperCase();

      this.errorDetailsSubject.next(msg);
      if (this.errorDetailsSubject.getValue() !== 'SERVER.ACTION.ERROR.001') {
        this.snackBar.openFromComponent(SnackBarTemplateComponent, {
          data: this.errorDetailsSubject,
          duration: 2500,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
      return throwError(msg);
    }
    return throwError('');
  }
}
