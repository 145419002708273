import { Injectable } from '@angular/core';
import { StatusEnum } from '../../interfaces/status.enum';
import { IPunchActive, IPunchInfo, IPunchProject, IPunchTask } from '../../interfaces/punch-info.interface';
import { IUserInformation } from '../../interfaces/user-information.interface';
import { AuthenticationService } from '../authentication/authentication.service';
import { RequestCachingService } from '../requests-caching/request-caching.service';
import { ConfigService } from '../config/config.service';

@Injectable({ providedIn: 'root' })
export class InterpreterPunchService {
  constructor(
    private requestCachingService: RequestCachingService,
    private configService: ConfigService,
    private authenticationService: AuthenticationService,
  ) {}

  buildActivePunch(activeSaved, projects): IPunchActive {
    const { tasks, ...project } = projects.find(({ projectId }) => projectId === activeSaved.projectId) || {};
    const task = tasks.find(({ taskId }) => taskId === activeSaved.taskId) || {};
    const { status, employeeFunctionId } = activeSaved;

    return this.formatActive({ status, project, task, employeeFunctionId });
  }

  formatActive({ status, project, task, employeeFunctionId }): IPunchActive {
    return {
      ...this.formatActiveProject(project),
      ...this.formatActiveTask(task),
      serverTime: new Date().valueOf(),
      serverTimezoneOffset: new Date().getTimezoneOffset(),
      status,
      employeeFunctionId,
    };
  }

  formatActiveProject(entity: any): IPunchProject {
    const { projectId, projectName, projectNumber, projectCategory, address, coordinates, employeeFunctionId } = entity;
    return {
      projectId,
      projectName,
      projectNumber,
      projectCategory,
      address,
      coordinates,
      employeeFunctionId,
    };
  }

  formatActiveTask({ taskId, taskName, taskNumber }): IPunchTask {
    return {
      taskId,
      taskName,
      taskNumber,
    };
  }

  async punch(dataQueue, data, resource: string) {
    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/punch`;
    const {
      value: { punchProjects, employeesFunctions },
    } = await this.requestCachingService.getItem(url, resource, this.getCurrentUserInformation());
    const active = this.buildActivePunch(data, punchProjects);
    const punchInfo: IPunchInfo = { punchProjects, employeesFunctions };
    if (active.status === StatusEnum.on) {
      punchInfo.active = active;
    }
    await this.requestCachingService.addItem(url, punchInfo, resource, this.getCurrentUserInformation());

    return { timeclock: { id: dataQueue.insertId }, punchInfo };
  }

  getCurrentUserInformation(): IUserInformation {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      const { user } = currentUser;
      const { employee } = currentUser;

      return {
        userId: user.id,
        employeeId: employee?.id,
        enterpriseId: employee?.enterpriseId,
      };
    }
  }

  geolocation(dataQueue: any, data: any, resourceType: string) {
    console.log('geolocation --> onSaveInterpreterData');
    return data;
  }
}
