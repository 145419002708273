import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IProject } from '@app/core/interfaces/project-detail.interface';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class ProjectDetailResolve implements Resolve<IProject> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProject> {
    const { projectId } = route.params;
    return this.get(projectId);
  }

  get(id: string): Observable<IProject> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IProject>(`${host}${apiUrl}/mobile/projects/${id}`);
  }
}
