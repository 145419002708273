import { Injectable, Optional } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { ConfigService } from '../config/config.service';

@Injectable({ providedIn: 'root' })
export class SqliteService {
  constructor(private platform: Platform, private configService: ConfigService, @Optional() private sqlite: SQLite) {
    this.sqlite = new SQLite();
  }

  getDataList(data) {
    const resData = [];
    for (let i = 0; i < data?.rows?.length; i++) {
      resData.push(data.rows.item(i));
    }
    return resData;
  }

  createTable(database: SQLiteObject, tableName: string, columns: string): Promise<void> {
    return database.executeSql(`CREATE TABLE IF NOT EXISTS ${tableName} (${columns})`, []);
  }

  async createDatabase(databaseName: string): Promise<SQLiteObject> {
    if (this.sqlite) {
      return this.sqlite.create({
        name: `${databaseName}.db`,
        location: 'default',
        createFromLocation: 1,
      });
    }
  }

  async initDatabase(databaseName: string) {
    try {
      await this.platform.ready();
      if (Capacitor.isNativePlatform() && this.configService.globalOptions.enableCache) {
        const database = await this.createDatabase(databaseName);
        await this.loggerTables(database);
        return database;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async insert(database: SQLiteObject, tableName: string, dataObj) {
    const keys = Object.keys(dataObj);
    const columns = keys.join(', ');
    const values = keys.map(() => '?').join(', ');

    const data = await database.executeSql(
      `INSERT INTO ${tableName} (${columns}) VALUES (${values})`,
      Object.values(dataObj),
    );
    await this.loggerTable(database, tableName);
    return data;
  }

  async loggerTables(database: SQLiteObject) {
    const sql = `SELECT  name
                   FROM sqlite_master
                  WHERE type ='table'
                    AND name NOT LIKE 'sqlite_%';`;
    await this.logger(database, sql, 'Tables name');
  }

  async loggerTable(database: SQLiteObject, tableName) {
    await this.logger(database, `SELECT * FROM ${tableName}`, `Table: ${tableName}`);
  }

  private logger(database: SQLiteObject, sql: string, info: string) {
    if (!this.configService.globalOptions.isProd) {
      let data;
      console.debug(`===== ${info} =====`);
      return database
        .executeSql(sql)
        .then((resData) => (data = resData))
        .catch((resData) => (data = resData))
        .finally(() => console.table(this.getDataList(data)));
    }
  }
}
