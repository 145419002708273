import * as Color from 'color';

const black87 = 'black';
const white87 = 'white';

export class CusColors {
  static getColor(colorHax: string): any {
    return {
      color: colorHax,
      contrast: CusColors.contrast(colorHax),
      light: Color(colorHax).lighten(0.15).hex(),
      dark: Color(colorHax).darken(0.15).hex(),
    };
  }

  private static contrast(colorHax) {
    const color = Color(colorHax);
    return color.isDark() ? white87 : black87;
  }
}
