import { NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { IonicGestureConfig } from './hammer/ionic-gesture-config';
import { PushNotificationsBehaviors } from './push-notifications/push-notifications.behaviors';
import { TranslationLoaderBehaviors } from './translate/translation-loader.behaviors';

@NgModule({
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
    PushNotificationsBehaviors,
    TranslationLoaderBehaviors,
  ],
})
export class BehaviorsModule {}
