import { RoleResourcesEnum } from '../../enums/role-resources.enum';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { RoleActionsEnum } from '../../enums/role.actions.enum';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface IPermission {
  resource: RoleResourcesEnum | string;
  action: RoleActionsEnum | string;
  condition: () => boolean;
}

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private jwtHelper = new JwtHelperService();
  private readonly TOKEN_GRANT = 'token-grant';

  constructor(private configService: ConfigService, private http: HttpClient) {}

  getRole(): string {
    const token = localStorage.getItem(this.TOKEN_GRANT);
    const { role } = this.jwtHelper.decodeToken(token);
    return role || '';
  }

  fetchGrants(): Observable<void> {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<{ token: string }>(`${host}${apiUrl}/public/auth/grants`).pipe(
      map(({ token }) => {
        localStorage.setItem(this.TOKEN_GRANT, token);
      }),
    );
  }

  can(resource: RoleResourcesEnum | string, action: RoleActionsEnum | string, condition = () => true): boolean {
    const { grant } = this.getGrants();
    const permission = grant[resource];
    if (!permission) {
      return false;
    }

    return (permission.actions[action] || permission.actions[RoleActionsEnum.ALL]) && condition();
  }

  canList(canList: IPermission[]): boolean {
    return canList.every(({ resource, action, condition }) => {
      const can = this.can(resource, action, condition);
      if (!can) {
        console.debug(`Can't access -> resource: ${resource} -> action: ${action}`);
      }
      return can;
    });
  }

  getGrants() {
    const token = localStorage.getItem(this.TOKEN_GRANT);
    if (token) {
      const { grants, authorizeIds } = this.jwtHelper.decodeToken(token);
      const { grant } = grants || {};
      return { grant, authorizeIds };
    }
    return {};
  }
}
