import { Injectable } from '@angular/core';

@Injectable()
export class SearchService {
  search(datalist: unknown[], searchStr: string, colsToSearch: string[] = []) {
    return datalist.filter((data) => this.searchObj(data, searchStr, colsToSearch));
  }

  searchFn(datalist: unknown[], searchFn: (data) => boolean) {
    return datalist.filter(searchFn);
  }

  searchObj(obj: unknown, searchStr: string, colsToSearch: string[]): boolean {
    const cols = colsToSearch.length ? colsToSearch : Object.keys(obj);
    return cols.some((col: string) => {
      const val = obj[col];
      if (this.invalidSearch(val)) {
        return false;
      }
      if (typeof val === 'object') {
        return this.searchObj(val, searchStr, colsToSearch);
      }
      return this.getValue(val).includes(this.getValue(searchStr));
    });
  }

  private invalidSearch(val) {
    return !val || (typeof val === 'object' && Array.isArray(val));
  }

  private getValue(val) {
    return `${val || ''}`
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
}
