import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PanelTitleComponent } from './panel-title.component';
import { StickyElementModule } from '../sticky-element/sticky-element.module';
import { ColorModule } from '../../directives/colors/color.module';
import { MaterialDesignModule } from '../../3p/material-design.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    MaterialDesignModule,
    TranslateModule,
    StickyElementModule,
    ColorModule,
  ],
  exports: [PanelTitleComponent],
  declarations: [PanelTitleComponent],
})
export class PanelTitleModule {}
