import { Pipe, PipeTransform } from '@angular/core';

interface ITime {
  millis: number;
  modulo: number;
}

export enum ETime {
  Seconds = 1000,
  Minutes = 60000,
  Hours = 3600000,
  SecInMin = 60,
  MinInHours = 60,
  HoursMod = Number.MAX_SAFE_INTEGER,
  timeMin = 10,
}

const times = {
  seconds: {
    millis: ETime.Seconds,
    modulo: ETime.SecInMin,
  },
  minutes: {
    millis: ETime.Minutes,
    modulo: ETime.MinInHours,
  },
  hours: {
    millis: ETime.Hours,
    modulo: ETime.HoursMod,
  },
};

@Pipe({ name: 'formatTimeDuration' })
export class FormatTimeDurationPipe implements PipeTransform {
  transform(time: number, fromNow = true): string {
    let duration = time;
    if (fromNow) {
      duration = new Date().valueOf() - time;
    }
    return this.msToTime(duration);
  }

  msToTime(duration: number, options: { showSeconds?: boolean } = { showSeconds: true }): string {
    const getTime = (divider: ITime): string => {
      const timeStr = Math.floor((duration / divider.millis) % divider.modulo);
      if (timeStr < 0) {
        return '00';
      }
      return timeStr < ETime.timeMin ? '0' + timeStr : String(timeStr);
    };

    const hours = getTime(times.hours);
    const minutes = getTime(times.minutes);
    const seconds = getTime(times.seconds);
    return `${hours}:${minutes}${options.showSeconds ? `:${seconds}` : ''}`;
  }
}
