import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IMaterial } from '@app/pages/custom-form/custom-form.page';
import { ConfigService } from '@app/core/services/config/config.service';

@Injectable()
export class MaterialsResolve implements Resolve<IMaterial[]> {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IMaterial[]> {
    return this.get();
  }

  get() {
    const { host, apiUrl } = this.configService.globalOptions;
    return this.http.get<IMaterial[]>(`${host}${apiUrl}/mobile/materials`);
  }
}
