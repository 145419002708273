import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { RequestsQueueService } from '../requests-queue/requests-queue.service';
import { NetworksService } from '../networks/networks.service';
import { IUserInformation } from '../../interfaces/user-information.interface';
import { ITimeclock } from '../../interfaces/timeclock.interface';
import { ConfigService } from '../config/config.service';

interface ITimeclockSynchronize {
  issues: any[];
  timeclocks: ITimeclock[];
}

@Injectable({ providedIn: 'root' })
export class RequestsQueueSynchronizerService {
  synchronizingTimecloks: boolean;

  constructor(
    private http: HttpClient,
    private networksService: NetworksService,
    private configService: ConfigService,
    private requestsQueue: RequestsQueueService,
  ) {
    this.networksService.getStatus().then(({connected}) => {
      if (connected) {
        this.synchronizeQueuedContent();
      }
    });
  }

  synchronizeQueuedContent() {
    if (!this.synchronizingTimecloks) {
      this.synchronizeTimeclocks();
    }
    // TODO: Add other synchronization resources
  }

  async synchronizeTimeclocks(): Promise<void> {
    this.synchronizingTimecloks = true;
    const queuedRequests = await this.requestsQueue.getQueuedRequests('punch');
    if (!queuedRequests || !queuedRequests.length) {
      this.synchronizingTimecloks = false;
      return;
    }
    const updateBody = queuedRequests.map(({ id, userId, employeeId, enterpriseId, requestBody }) => {
      const userInformation = { userId, employeeId, enterpriseId, punchId: id } as IUserInformation;
      return { userInformation, body: { ...JSON.parse(requestBody), employeeId, enterpriseId } };
    });

    // TODO: Get machine token for call
    // - Skip JWT interceptor?
    const skipInterceptorsHeaders = {
      'skip-cache': 'true',
    };

    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/timeclocks/synchronize`;
    this.http
      .post<{ issues: any[]; timeclocks: ITimeclock[] }>(url, updateBody, { headers: skipInterceptorsHeaders })
      .pipe(first())
      .subscribe(async ({ issues, timeclocks }) => {
        try {
          await this.synchronizeGeolocation(timeclocks);
        } finally {
          const issuesPunchIds = issues.map(({ punchId }) => punchId);
          const timeclockPunchIds = timeclocks.map(({ punchId }) => punchId);
          await this.requestsQueue
            .clearRequestQueueById('punch', [...timeclockPunchIds, ...issuesPunchIds])
            .then(() => {
              this.synchronizingTimecloks = false;
            });
        }
      });
  }

  async synchronizeGeolocation(timeclocks: ITimeclock[]): Promise<void> {
    const queuedRequests = await this.requestsQueue.getQueuedRequests('geolocation');
    if (!queuedRequests || !queuedRequests.length) {
      return;
    }

    const updateBody = queuedRequests.reduce((acc, { requestBody }) => {
      const geo = JSON.parse(requestBody);
      const tc = timeclocks.find(({ punchId }) => punchId === geo.timeclockId);
      if (tc) {
        acc.push({ ...geo, timeclockId: tc.id });
      }
      return acc;
    }, []);

    // TODO: Get machine token for call
    // - Skip JWT interceptor?
    const skipInterceptorsHeaders = {
      'skip-cache': 'true',
    };

    const { host, apiUrl } = this.configService.globalOptions;
    const url = `${host}${apiUrl}/mobile/geolocation/synchronize`;
    await this.http.post(url, updateBody, { headers: skipInterceptorsHeaders }).toPromise();
    const geolocationIds = queuedRequests.map(({ id }) => id);
    await this.requestsQueue.clearRequestQueueById('geolocation', geolocationIds);
  }
}
